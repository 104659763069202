import axios from "axios";
import {
  SETTINGS_GET_PROJECT_STATUS,
  SETTINGS_ADD_PROJECT_STATUS_SUCCESS,
  SETTINGS_ADD_PROJECT_STATUS_ERROR,
  SETTINGS_GET_PROJECT_STATUS_LOADING,
  SETTINGS_DELETE_PROJECT_STATUS_SUCCESS,
  SETTINGS_PROJECT_STATUS_ERROR,
  SETTINGS_PROJECT_STATUS_CLEAR_SNACKBAR,
  SETTINGS_ADD_PROJECT_STATUS,
} from "../constants/types";
import queryString from "query-string";

// import { bulkUploadFiles } from "./documentsActions";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchProjectStatus = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_PROJECT_STATUS_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PageNumber: params.page ? params.page + 1 : 1,
        PageSize: params.pageSize ? params.pageSize : 10,
      };
      const queryParams = queryString.stringify(newParams);

      request({
        url: `${server_url}/ProjectStatus`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: SETTINGS_GET_PROJECT_STATUS,
            payload: {
              projectStatus: response.data,
              totalCount: response.data.length,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_PROJECT_STATUS_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting all projectStatus.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateProjectStatus = ({ formData, onSuccess, payload }) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_ADD_PROJECT_STATUS,
        payload: {
          loading: true,
        },
      });

      let data = {
        ...formData,
      };

      request({
        method: formData.projectStatusId ? "PUT" : "POST",
        url: `${server_url}/ProjectStatus${
          formData.projectStatusId ? `/${formData.projectStatusId}` : ""
        }`,
        body: { ...data },
      })
        .then(async (response) => {
          onSuccess();

          dispatch({
            type: SETTINGS_ADD_PROJECT_STATUS_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: formData.projectStatusId
                ? "Project status updated Successfully"
                : "Project status Created Successfully",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_ADD_PROJECT_STATUS_ERROR,
            payload: {
              text: "Error occurred during creating projectStatus data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const projectStatusDelete = ({
  projectStatusId,
  pageModel,
  onSuccess = () => {},
  showSnackbar = true,
}) => {
  console.log("Check the projectStatusDelete Action", projectStatusId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_PROJECT_STATUS_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/ProjectStatus/${projectStatusId}`,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(fetchProjectStatus({ ...pageModel }));
          onSuccess();

          dispatch({
            type: SETTINGS_DELETE_PROJECT_STATUS_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "projectStatus Deleted Successfully",
              loading: false,
              showSnackbar: true,
            },
          });

          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_PROJECT_STATUS_ERROR,
            payload: {
              text: "Error occurred during deleting projectStatus data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const projectStatusClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SETTINGS_PROJECT_STATUS_CLEAR_SNACKBAR,
    });
  };
};
