import request from "config/api";
import {
	showAppApiAlert,
	startAppLoadAction,
	stopAppLoadAction,
} from "./appActions";

export const apiCallAction = ({
	requestMethod = "GET",
	requestUrl,
	requestData = {},
	actionType,
	successType,
	errorType,
	successMessage = "Request successful.",
	defaultSuccessAction = false,
	errorMessage = "An error occurred.",
	extraParams = {}, // Optional extra params
	successCallback = () => {},
	errorCallback = () => {},
}) => {
	console.log("Check the API Call Action", requestMethod, requestData);
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch(startAppLoadAction());
			dispatch({
				type: actionType,
				payload: {
					loading: true,
					...extraParams, // Additional params if needed
				},
			});

			request({
				method: requestMethod,
				url: requestUrl,
				body: requestData,
			})
				.then((response) => {
					if (defaultSuccessAction) {
						dispatch({
							type: successType,
							payload: {
								data: response.data,
							},
						});
					}
					successCallback(response, dispatch);
					resolve(response.data);
					dispatch(stopAppLoadAction());
				})
				.catch((error) => {
					dispatch({
						type: errorType,
						payload: {
							status: "error",
							text: errorMessage,
							loading: false,
						},
					});
					errorCallback(error, dispatch);
					dispatch(stopAppLoadAction());
					dispatch(showAppApiAlert(errorMessage, error));
					resolve(null);
				});
		});
	};
};
