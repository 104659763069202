import axios from "axios";
import {
  SETTINGS_JOBTYPE_CLEAR_SNACKBAR,
  SETTINGS_JOBTYPE_ERROR,
  SETTINGS_DELETE_JOBTYPE_SUCCESS,
  SETTINGS_GET_JOBTYPE_LOADING,
  SETTINGS_ADD_JOBTYPE_ERROR,
  SETTINGS_ADD_JOBTYPE_SUCCESS,
  SETTINGS_ADD_JOBTYPE,
  SETTINGS_GET_JOBTYPE,
} from "../constants/types";
import queryString from "query-string";

// import { bulkUploadFiles } from "./documentsActions";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchJobType = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_JOBTYPE_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PageNumber: params.page ? params.page + 1 : 1,
        PageSize: params.pageSize ? params.pageSize : 10,
      };
      const queryParams = queryString.stringify(newParams);

      request({
        url: `${server_url}/JobType`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: SETTINGS_GET_JOBTYPE,
            payload: {
              jobType: response.data,
              totalCount: response.data.length,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_JOBTYPE_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting all JobType.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateJobType = ({ formData, onSuccess, payload }) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_ADD_JOBTYPE,
        payload: {
          loading: true,
        },
      });

      let data = {
        ...formData,
      };

      request({
        method: formData.jobTypeId ? "PUT" : "POST",
        url: `${server_url}/JobType${
          formData.jobTypeId ? `/${formData.jobTypeId}` : ""
        }`,
        body: { ...data },
      })
        .then(async (response) => {
          onSuccess();

          dispatch({
            type: SETTINGS_ADD_JOBTYPE_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: formData.jobTypeId
                ? "JobType updated Successfully"
                : "JobType Created Successfully",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_ADD_JOBTYPE_ERROR,
            payload: {
              text: "Error occurred during creating jobType data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const jobTypeDelete = ({
  jobTypeId,
  pageModel,
  onSuccess = () => {},
  showSnackbar = true,
}) => {
  console.log("Check the jobTypeDelete Action", jobTypeId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_JOBTYPE_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/JobType/${jobTypeId}`,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(fetchJobType({ ...pageModel }));
          onSuccess();

          dispatch({
            type: SETTINGS_DELETE_JOBTYPE_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "jobType Deleted Successfully",
              loading: false,
              showSnackbar: true,
            },
          });

          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_JOBTYPE_ERROR,
            payload: {
              text: "Error occurred during deleting jobType data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const jobTypeClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SETTINGS_JOBTYPE_CLEAR_SNACKBAR,
    });
  };
};
