import {
	CameraFront,
	Close,
	Flip,
	FlipCameraIos,
	PhotoCamera,
} from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const CameraCapture = ({ onImageCapture = () => {}, onClose = () => {} }) => {
	const [stream, setStream] = useState(null);
	const videoRef = useRef(null);
	const [isFrontCamera, setIsFrontCamera] = useState(true); // Add state for camera type

	const startCamera = async () => {
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: isFrontCamera ? "user" : "environment" },
			});
			setStream(mediaStream);

			if (videoRef.current) {
				videoRef.current.srcObject = mediaStream;
			}
		} catch (err) {
			console.error("Error accessing camera:", err);
		}
	};

	const stopCamera = () => {
		if (stream) {
			stream.getTracks().forEach((track) => track.stop());
			setStream(null);
		}
	};

	const takePhoto = () => {
		if (videoRef.current) {
			const canvas = document.createElement("canvas");
			canvas.width = videoRef.current.videoWidth;
			canvas.height = videoRef.current.videoHeight;

			const ctx = canvas.getContext("2d");
			// Mirror the image if using front camera
			if (isFrontCamera) {
				ctx.scale(-1, 1);
				ctx.translate(-canvas.width, 0);
			}
			ctx.drawImage(videoRef.current, 0, 0);

			const previewUrl = canvas.toDataURL("image/jpeg");

			canvas.toBlob((blob) => {
				const file = new File([blob], `photo_${Date.now()}.jpg`, {
					type: "image/jpeg",
				});

				onImageCapture({
					file: file,
					imagePreviewUrl: previewUrl,
				});

				handleCloseCamera();
			}, "image/jpeg");
		}
	};

	const handleCloseCamera = () => {
		stopCamera();
		onClose();
	};

	useEffect(() => {
		async function initializeCamera() {
			await startCamera();
		}
		initializeCamera();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			if (stream) {
				stream.getTracks().forEach((track) => track.stop());
			}
		};
	}, [stream]);

	const toggleCamera = async () => {
		stopCamera();
		setIsFrontCamera(!isFrontCamera);
		await startCamera();
	};
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					bgcolor: "black",
					display: "flex",
					flexDirection: "column",
				}}
			>
				{/* <Paper sx={{ p: 2, maxWidth: "90vw", width: "500px" }}>
					<div style={{ display: "flex", justifyContent: "flex-end" }}> */}
				<Box sx={{ p: 1, display: "flex", justifyContent: "flex-end" }}>
					<IconButton onClick={handleCloseCamera} sx={{ color: "white" }}>
						<Close />{" "}
					</IconButton>

					{/* </div> */}
				</Box>
				<Box sx={{ p: 1, display: "flex", justifyContent: "flex-end" }}>
					<FlipCameraIos
						style={{ color: "white", cursor: "pointer" }}
						onClick={toggleCamera}
					/>{" "}
					{/* Import this icon from @mui/icons-material */}
				</Box>

				{/* <div
						style={{ display: "flex", flexDirection: "column", gap: "16px" }}
					> */}
				{/* <div
						style={{
							width: "100%",
							aspectRatio: "1",
							position: "relative",
							overflow: "hidden",
							backgroundColor: "#f0f0f0",
							borderRadius: "4px",
						}}
					> */}
				<video
					ref={videoRef}
					autoPlay
					playsInline
					style={{
						width: "100%",
						height: "100%",
						objectFit: "cover",
						transform: isFrontCamera ? "scaleX(-1)" : "none",
					}}
				/>
				{/* </div> */}

				<Box
					sx={{
						p: 2,
						display: "flex",
						justifyContent: "center",
						bgcolor: "black",
					}}
				>
					<IconButton
						onClick={takePhoto}
						sx={{
							width: 60,
							height: 60,
							border: "3px solid white",
							color: "white",
						}}
					>
						<PhotoCamera />
					</IconButton>
				</Box>
				{/* </div> */}
			</Box>
			{/* </Paper> */}
		</>
	);
};
export default CameraCapture;
