import { TextField, Typography } from "@mui/material";
import { convertEstimateToProjectAction } from "actions/estimationsActions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const RenderActionsContent = (props) => {
	if (props.modelType === "send") {
		return <EstimateSendAction {...props} />;
	}
	if (props.modelType === "createProject") {
		return <EstimateProjectCreate {...props} />;
	}
	return <></>;
};

export const EstimateSendAction = (props) => {
	const [emailId, setEmailId] = useState("");

	return (
		<>
			<div>
				<Typography className="h4">
					Please enter email address to send this estimate copy
				</Typography>
				<div className="d-flex mt-3 align-center">
					<Typography className="col-3 h6 me-3">Email Address:</Typography>
					<TextField
						className="form-field"
						onChange={(e) => {
							setEmailId(e.target.value);
						}}
					/>
				</div>
				<div className="d-flex mt-3 justify-content-center">
					<button
						className="primaryButton"
						style={{ background: "red", color: "white", width: "6rem" }}
						onClick={() => props.handleClose()}
					>
						Cancel
					</button>
					<button
						className="primaryButton disabled"
						style={{ background: "#28a745", color: "white" }}
						onClick={() => {
							console.log("Email ID ", emailId);
						}}
						disabled
					>
						Send Estimation
					</button>
				</div>
				<Typography
					variant="subtitle1"
					style={{ color: "red", fontSize: "14px" }}
				>
					This Functionality is currently under development, will be enabled
					Soon...
				</Typography>
			</div>
		</>
	);
};

export const EstimateProjectCreate = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<>
			<div>
				<Typography className={"h3"}>
					Do you want to create a Project and related Jobs with this Estimation
					?
				</Typography>
				<div className="d-flex justify-content-center mt-4">
					<button
						className="primaryButton"
						style={{ background: "red", width: "6rem", color: "white" }}
						onClick={() => props.handleClose()}
					>
						Cancel
					</button>
					<button
						className="primaryButton"
						style={{ background: "#28a745", color: "white" }}
						onClick={() => {
							dispatch(
								convertEstimateToProjectAction(
									props.estimateData.estimateId,
									{},
									() => {
										props.handleClose();
										if (props.estimateData?.propertyId) {
											navigate(
												`/properties/${props.estimateData?.propertyId}`,
												{
													state: {
														propertyId: props.estimateData?.propertyId,
														propertyName: props.estimateData?.propertyName,
													},
												}
											);
										}
									}
								)
							);
						}}
					>
						Create Project
					</button>
				</div>
			</div>
		</>
	);
};
