import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { Delete, EditOutlined } from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import { MenuItem, Typography } from "@mui/material";
import get from "lodash/get";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";
import { getMomentDate } from "core/utils/appUtils";
import startCase from "lodash/startCase";
import Tooltip from "components/Tooltip/Tooltip";
import "./tasks.scss";

const getTextContent = (key) => getText(`tasks.addTask.${key}`);

const addTaskValidationSchema = yup.object({
	taskName: yup.string("Enter Task Name").required("Task Name is required"),
	taskStatusId: yup.string("Select Status").required("Status is required"),
});

export const addTaskFields = [
	{
		key: "taskName",
		label: getTextContent("taskName"),
		placeholder: "Task Name",
		type: "text",
		required: true,
		width: "100%",
	},
	{
		key: "taskNotes",
		label: "Task Notes",
		placeholder: "Enter Task Details here",
		type: "text",
		extraInputProps: {
			multiline: true,
			className: "registration-formField task-notes property-details-textarea",
		},
		width: "100%",
		className: "task-details",
	},
	{
		key: "taskStatusId",
		label: "Task Status",
		placeholder: "Select Status",
		type: "select",
		select: true,
		options: [],
		required: true,
		width: "50%",
	},
	{
		key: "assignedTo",
		label: "Assigned To",
		placeholder: "Select Assignee",
		type: "select",
		select: true,
		options: [],
		width: "50%",
	},
	{
		key: "plannedStartDate",
		placeholder: "MM/DD/YYYY",
		label: getTextContent("plannedStartDate"),
		type: "dateField",
		width: "50%",
	},
	{
		key: "plannedEndDate",
		placeholder: "MM/DD/YYYY",
		label: getTextContent("plannedEndDate"),
		type: "dateField",
		width: "50%",
	},
	{
		key: "actualStartDate",
		placeholder: "MM/DD/YYYY",
		label: getTextContent("actualStartDate"),
		type: "dateField",
		// required: true,
		width: "50%",
	},
	{
		key: "actualEndDate",
		placeholder: "MM/DD/YYYY",
		label: getTextContent("actualEndDate"),
		type: "dateField",
		// required: true,
		width: "50%",
	},
];

export const addTaskForm = {
	validationSchema: addTaskValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		}
		return Object.fromEntries(
			Object.keys(addTaskValidationSchema.fields).map((key) => [key, ""])
		);
	},
};

export const taskListColumns = (props) => [
	{
		field: "taskName",
		headerName: "Task Name",
		sortable: false,
		width: 200,
		resizable: true,
		renderCell: (params) => {
			return (
				<Tooltip title={params.row.taskName}>
					<Typography variant="body1" className="underline-text">
						{params.row.taskName}
					</Typography>
				</Tooltip>
			);
		},
	},
	{
		field: "taskNotes",
		headerName: "Details",
		resizable: true,
		width: 250,
	},
	{
		field: "taskStatus",
		headerName: "Status",
		resizable: true,
		width: 150,
		// valueFormatter: (value) => priority[value],
		renderCell: (params) => {
			return (
				<TaskStatus status={get(params, "row.taskStatus.statusName", "New")} />
			);
		},
	},
	{
		field: "plannedStartDate",
		headerName: "Start Date",
		resizable: true,
		width: 150,
		valueFormatter: (value) => getMomentDate(value),
	},
	{
		field: "plannedEndDate",
		headerName: "End Date",
		resizable: true,
		width: 150,
		valueFormatter: (value) => getMomentDate(value),
	},
	{
		field: "actions",
		type: "actions",
		flex: 1,
		width: 100,
		align: "right",
		getActions: (params) => [
			<BurgerMenu>
				<MenuItem
					onClick={() => {
						console.log("Check the Params : ", params);
						props.setEditTask(true);
						props.setAddNewTask(true);
						props.setSelectedTask({ id: params.id, data: params.row });
					}}
				>
					<EditOutlined /> <p className="m-0">Edit</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.onTaskDelete(params.id);
					}}
				>
					<Delete /> Delete Task
				</MenuItem>
			</BurgerMenu>,
		],
	},
];

export const TaskStatus = ({
	status,
	statusColors = {
		New: "#8B8B8B",
		OnHold: "#FF5630",
		"On Hold": "#FF5630",
		InProgress: "#FFBA08",
		"In Progress": "#FFBA08",
		Completed: "#36B37E",
	},
}) => {
	return (
		<>
			<div
				style={{
					border: `1px solid ${statusColors[status] || "#8B8B8B"}`,
				}}
				className="d-flex align-items-center task-status-div"
			>
				<StatusIndicator
					color={statusColors[status]}
					className="task-indicator-circle "
				/>
				<Typography variant="body1" className="task-status-text">
					{startCase(status)}
				</Typography>
			</div>
		</>
	);
};
