import React, { useCallback, useEffect, useState } from "react";
import {
	Close,
	Delete,
	EditOutlined,
	EmailOutlined,
	MoreVert,
} from "@mui/icons-material";
import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	IconButton,
	MenuItem,
	MenuList,
	Popover,
	TextField,
	Typography,
} from "@mui/material";
import { fetchEstimatesAction } from "actions/estimationsActions";
import { CustomBreadCrumb } from "components/BreadCrumb";
import DataGridTable from "components/Table/DataGrid";
import HiddenOn from "components/Wrappers/HiddenOn";
import { useDispatch, useSelector } from "react-redux";
import { ModelDialog } from "components/Model";
import { CreateEstimateContent } from "./CreateEstimate";
import { getEstimatesList } from "selectors/estimatesSelectors";
import { useNavigate } from "react-router-dom";
import { RenderActionsContent } from "./RenderEstimateActions";

export const EstimatesList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [createModel, setCreateModel] = useState(false);

	const [openModel, setOpenModel] = useState(false);
	const [modelType, setModelType] = useState();

	const [selectedEstimate, setSelectedEstimate] = useState();

	const { estimatesList, totalCount } = useSelector(getEstimatesList);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const fetchEstimations = useCallback(() => {
		dispatch(fetchEstimatesAction());
	}, [dispatch]);

	useEffect(() => {
		fetchEstimations();
	}, [fetchEstimations]);

	const columns = [
		{
			field: "estimateName",
			headerName: "Estimate Name",
			width: 150,
			renderCell: ({ value, row }) => {
				return (
					<Typography
						variant="body1"
						className="pointer underline-text link-text"
						onClick={() => {
							navigate(`/builder/estimations/${row.estimateId}`, {
								state: {
									estimateName: row.estimateName,
									estimateId: row.estimateId,
								},
							});
						}}
					>
						{value}
					</Typography>
				);
			},
		},
		{
			field: "propertyAddress",
			headerName: "Address",
			width: 200,
			renderCell: ({ row }) => {
				return <Typography varinat="body1">{row.property.address}</Typography>;
			},
		},
		{
			field: "description",
			headerName: "Notes",
			width: 150,
		},
		{
			field: "homeowner",
			headerName: "Homeowner",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<Typography varinat="body1">{row.property.homeOwnerName}</Typography>
				);
			},
		},
		{
			field: "contact",
			headerName: "Contact",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<Typography varinat="body1">
						{row.property.homeOwnerMobile}
					</Typography>
				);
			},
		},
		{
			field: "actions",
			headerName: "",
			align: "right",
			headerAlign: "right", // Align header text to right
			width: 350,
			renderCell: ({ row }) => {
				return (
					<Box display="flex" justifyContent="flex-end" gap={1} width="100%">
						<Typography
							variant="body1"
							className="primaryButton p-2"
							style={{ color: "white", background: "#28a745" }}
							onClick={() => {
								setModelType("createProject");
								setOpenModel(true);
								setSelectedEstimate(row);
							}}
						>
							Create Project
						</Typography>
						<Typography
							variant="body1"
							className="primaryButton p-2"
							style={{ color: "white" }}
							onClick={() => {
								setModelType("send");
								setOpenModel(true);
								setSelectedEstimate(row);
							}}
						>
							{" "}
							<EmailOutlined
								style={{ fontSize: "18px", marginRight: "0.7rem" }}
							/>
							Send
						</Typography>
						<IconButton
							aria-describedby={
								Boolean(anchorEl) ? "estimates-more-action" : undefined
							}
							onClick={handleClick}
							size="small"
						>
							<MoreVert fontSize="small" />
						</IconButton>
						<Popover
							id={Boolean(anchorEl) ? "estimates-more-action" : undefined}
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => {
								setAnchorEl(null);
							}}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
						>
							<MenuList>
								<MenuItem
									onClick={() => {
										navigate(`/builder/estimations/${row.estimateId}`, {
											state: {
												estimateName: row.estimateName,
												estimateId: row.estimateId,
											},
										});
									}}
								>
									Update Estimate
								</MenuItem>
								<MenuItem disabled>Delete Estimate</MenuItem>
							</MenuList>
						</Popover>
					</Box>
				);
			},
		},
	];

	return (
		<div style={{ background: "#DDDDDD" }}>
			<ModelDialog
				open={createModel}
				handleClose={() => {
					setCreateModel(false);
				}}
				dialogTitle={
					<div className="d-flex justify-content-between text-center align-center align-items-center">
						<Typography className="h5">Create New Estimate</Typography>
						<Close
							style={{ cursor: "pointer", fontSize: "16px" }}
							onClick={() => setCreateModel(false)}
						/>
					</div>
				}
				fullWidth={true}
				dialogContent={
					<CreateEstimateContent
						// template={templateFields}
						handleClose={(responsePayload) => {
							setCreateModel(false);
							if (responsePayload && responsePayload.estimateId) {
								navigate(`/builder/estimations/${responsePayload.estimateId}`);
							}
						}}
					/>
				}
			/>
			<ModelDialog
				open={openModel}
				handleClose={() => {
					setCreateModel(false);
				}}
				fullWidth={true}
				dialogContent={
					<RenderActionsContent
						modelType={modelType}
						estimateData={selectedEstimate}
						handleClose={() => {
							setOpenModel(false);
							setModelType();
						}}
					/>
				}
			/>
			<div className="estimates-list-container  p-2">
				<div className=" d-flex justify-content-between">
					<div>
						<Typography className="h4 m-2 mt-3">Estimates</Typography>
						<CustomBreadCrumb breadCrumbItems={["estimates"]} />
					</div>
					<button
						className="create-estimate-button primaryButton m-2"
						style={{ width: "auto" }}
						onClick={() => {
							setCreateModel(true);
						}}
					>
						Create New Estimate
					</button>
				</div>
				<div>
					<HiddenOn isHidden={false}>
						<Typography className="total-count-text mt-3 mb-3">
							Displaying {`0`}
							of {0} Estimates
						</Typography>
						<Divider className="divider" />

						<div data-testid={"properties-select-div "} className="d-flex">
							<FormGroup className="ms-3" aria-disabled>
								<FormControlLabel
									disabled
									disableTypography
									control={<Checkbox />}
									label="Select All"
								/>
							</FormGroup>
							<IconButton disabled={true} onClick={() => {}}>
								<Delete
									style={{
										fontSize: "20px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Delete</strong>
								</Typography>
							</IconButton>
							<IconButton
								disabled={true}
								// onClick={() =>
								// 	navigate(`/property/edit/${selectedRecord.propertyId}`)
								// }
							>
								<EditOutlined
									style={{
										fontSize: "20px",
										marginTop: "5px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Edit</strong>
								</Typography>
							</IconButton>
						</div>
					</HiddenOn>
				</div>
				<div className="esitmates-list-table-sec">
					<DataGridTable
						columns={columns}
						data={estimatesList}
						showToolbar={false}
						defineRowId={(row) => row.estimateId}
						checkboxSelection={false}
						pagination={false}
					/>
				</div>
			</div>
		</div>
	);
};

export default EstimatesList;
