import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.jobType;

export const getJobType = createSelector(selectState, (jobType) => ({
  jobType: get(jobType, "jobType", []),
  totalCount: get(jobType, "totalCount", 0),
  loading: get(jobType, "loading", false),
  showSnackbar: get(jobType, "showSnackbar", false),
  status: get(jobType, "status", "success"),
  statusText: get(jobType, "text", ""),
}));
