import { ActionConstants } from "../constants";
import { server_url } from "constants/types";
import queryString from "query-string";
import { apiCallAction } from "./actionUtils";
import { bulkUploadFiles } from "./documentsActions";

export const fetchPunchListsAction = (params) => {
	return apiCallAction({
		requestMethod: "GET",
		requestUrl: `${server_url}/PunchList?${queryString.stringify(params)}`,
		actionType: ActionConstants.FETCH_PUNCH_LISTS,
		successType: ActionConstants.FETCH_PUNCH_LISTS_SUCCESS,
		errorType: ActionConstants.FETCH_PUNCH_LISTS_ERROR,
		successCallback: (response, dispatch) => {
			dispatch({
				type: ActionConstants.FETCH_PUNCH_LISTS_SUCCESS,
				payload: {
					punchLists: response.data.items,
					totalCount: response.data.totalCount,
				},
			});
		},
	});
};

export const fetchPunchTypeListAction = (params) => {
	const queryParams = queryString.stringify(params);
	return apiCallAction({
		requestMethod: "GET",
		requestUrl: `${server_url}/PunchListType?${queryParams}`,
		actionType: ActionConstants.FETCH_PUNCH_TYPE_LIST,
		successType: ActionConstants.FETCH_PUNCH_TYPE_LIST_SUCCESS,
		errorType: ActionConstants.FETCH_PUNCH_TYPE_LIST_ERROR,
		successCallback: (response, dispatch) => {
			dispatch({
				type: ActionConstants.FETCH_PUNCH_TYPE_LIST_SUCCESS,
				payload: response.data,
			});
		},
	});
};

export const fetchPunchPriorityListAction = (params) => {
	const queryParams = queryString.stringify(params);
	return apiCallAction({
		requestMethod: "GET",
		requestUrl: `${server_url}/PunchListPriority?${queryParams}`,
		actionType: ActionConstants.FETCH_PUNCH_PRIORITIES_LIST,
		successType: ActionConstants.FETCH_PUNCH_PRIORITIES_LIST_SUCCESS,
		errorType: ActionConstants.FETCH_PUNCH_PRIORITIES_LIST_ERROR,
		successCallback: (response, dispatch) => {
			dispatch({
				type: ActionConstants.FETCH_PUNCH_PRIORITIES_LIST_SUCCESS,
				payload: response.data,
			});
		},
	});
};

export const fetchPunchStatusListAction = (params) => {
	const queryParams = queryString.stringify(params);
	return apiCallAction({
		requestMethod: "GET",
		requestUrl: `${server_url}/PunchListStatusType?${queryParams}`,
		actionType: ActionConstants.FETCH_PUNCH_STATUS_LIST,
		successType: ActionConstants.FETCH_PUNCH_STATUS_LIST_SUCCESS,
		errorType: ActionConstants.FETCH_PUNCH_STATUS_LIST_ERROR,
		successCallback: (response, dispatch) => {
			dispatch({
				type: ActionConstants.FETCH_PUNCH_STATUS_LIST_SUCCESS,
				payload: response.data,
			});
		},
	});
};

export const addUpdatePunchListAction = (payload) => {
	console.log("Check the payload", payload);
	const { punchListId } = payload.formData;
	const { formData, filesData, onSuccess } = payload;
	return apiCallAction({
		// requestMethod: formData.punchListId ? "PUT" : "POST",
		requestMethod: punchListId ? "PUT" : "POST",
		requestUrl: `${server_url}/PunchList${
			punchListId ? `/${punchListId}` : ""
		}`,
		requestData: formData,
		actionType: ActionConstants.CREATE_UPDATE_PUNCH_LIST,
		successType: ActionConstants.CREATE_UPDATE_PUNCH_LIST_SUCCESS,
		errorType: ActionConstants.CREATE_UPDATE_PUNCH_LIST_ERROR,
		successCallback: (response, dispatch) => {
			dispatch({
				type: ActionConstants.CREATE_UPDATE_PUNCH_LIST_SUCCESS,
				payload: response.data,
			});
			// onSuccess(response.data);
			const { filesState, fileLabelsState, documentTypeState } = filesData;
			dispatch(
				bulkUploadFiles(
					filesState,
					fileLabelsState,
					documentTypeState,
					formData.punchListId || response.data.punchListId,
					"PunchList",
					onSuccess(response.data)
				)
			);
			dispatch(fetchPunchListsAction(payload.params));
		},
	});
};

export const fetchPunchItemByID = (punchItemId, params) => {
	return apiCallAction({
		requestMethod: "GET",
		requestUrl: `${server_url}/PunchList/${punchItemId}?${queryString.stringify(
			params
		)}`,
		actionType: ActionConstants.FETCH_PUNCH_ITEM_BY_ID,
		successType: ActionConstants.FETCH_PUNCH_ITEM_BY_ID_SUCCESS,
		errorType: ActionConstants.FETCH_PUNCH_LISTS_ERROR,
		successCallback: (response, dispatch) => {
			dispatch({
				type: ActionConstants.FETCH_PUNCH_ITEM_BY_ID_SUCCESS,
				payload: response.data,
			});
		},
	});
};
