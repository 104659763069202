import { ArrowBack, Attachment, EditOutlined, Room } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { fetchPunchItemByID } from "actions/punchListActions";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPunchItem } from "selectors/punchListSelector";
import get from "lodash/get";
import { statusColors } from "../PunchList";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";
import { TaskStatus } from "pages/task/addTaskUtils";
import { fetchFiles } from "actions/documentsActions";
import { selectAllFiles } from "selectors/documentsSelectors";
import FilesList from "components/FileList/FileList";

export const ViewPunchItem_Mobile = ({
	projectId,
	propertyId,
	punchItemId,
	onEditPunchItem,
	onCancel,
}) => {
	const dispatch = useDispatch();

	const punchData = useSelector(getPunchItem);

	useEffect(() => {
		dispatch(fetchPunchItemByID(punchItemId));
	}, [dispatch, punchItemId]);

	const existingFiles = useSelector(selectAllFiles);
	// const isUploading = useSelector(selectIsUploading);

	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});
	const [documentTypeState, setDocumentTypeState] = React.useState("");

	const fetchExistingDocs = useCallback(() => {
		dispatch(fetchFiles("PunchList", punchItemId));
	}, [dispatch, punchItemId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	return (
		<>
			<div className={"view-punch-mobile"}>
				<div className={"view-punch-mobile-header"}>
					<Paper
						className={
							"view-punch-mobile-header-paper d-flex flex-wrap justify-content-between pt-3 pb-3"
						}
					>
						<div className={"view-punch-mobile-header-title d-flex col-8"}>
							<ArrowBack
								size="small"
								className="pointer"
								onClick={() => onCancel()}
							/>
							<Typography className="h3 ms-3 overflowText col-9">
								{punchData.title}
							</Typography>
						</div>
						<div className="d-flex justify-content-end col-3 me-2">
							<button
								className="primaryButton disabled"
								onClick={onEditPunchItem}
								// disabled
							>
								<EditOutlined style={{ fontSize: "16px" }} /> Edit
							</button>
						</div>
					</Paper>
				</div>
				<div className="view-punch-mobile-body m-3">
					<div className="PL-card-priority card-tags d-flex">
						<div
							style={{
								background: "lightgray",
								padding: "3px",
								alignItems: "center",
								width: "5rem",
							}}
							className="d-flex m-2 punchItem-type-tag"
						>
							<Typography
								variant="body1"
								style={{ fontSize: "12px", margin: "auto" }}
							>
								{get(punchData, "punchListType.punchListType", "N/A")}
							</Typography>
						</div>
						<div
							style={{
								background:
									statusColors[
										get(
											punchData,
											"punchListPriority.punchListPriority",
											"Medium"
										)
									],
								color: "white",
								padding: "3px",
								alignItems: "center",
								width: "5rem",
								margin: "auto",
							}}
							className="m-2 d-flex priority-tag"
						>
							<StatusIndicator
								color="white"
								width={"10px"}
								height={"10px"}
								style={{
									margin: "0 5px",
									position: "relative",
								}}
							/>
							<Typography
								variant="body1"
								style={{ fontSize: "12px", color: "white" }}
							>
								{get(
									punchData,
									"punchListPriority.punchListPriority",
									"Medium"
								)}
							</Typography>
						</div>
					</div>
					<Typography className="h3  mb-2 p-2 col-9">
						{punchData.title}
					</Typography>
					<Typography
						className="body1 mb-2 p-2 col-9"
						style={{ fontWeight: "500" }}
					>
						{punchData.description}
					</Typography>
					<div className="PL-card-footer d-flex justify-content-between align-items-center mt-3 p-2">
						<div className="PL-card-footer-location d-flex align-items-center">
							<Room style={{ fontSize: "16px", marginRight: "5px" }} />
							<Typography variant="body1" style={{ fontSize: "16px" }}>
								{punchData.location}
							</Typography>
						</div>
						<div className="PL-card-footer-status d-flex">
							<TaskStatus
								status={get(
									punchData,
									"punchListStatusType.punchListStatusType",
									"New"
								)}
							/>
						</div>
					</div>
					<div>
						<div className="mt-4 PL-card-footer-docs d-flex align-items-center">
							<Attachment style={{ fontSize: "16px", marginRight: "5px" }} />
							<Typography variant="body1" style={{ fontSize: "16px" }}>
								{existingFiles.length || 3}
							</Typography>
						</div>
					</div>
					<div className="mt-4">
						<FilesList files={existingFiles} showToolbar />
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewPunchItem_Mobile;
