export const ADD_NEW_PROPERTY = "ADD_NEW_PROPERTY";
export const ADD_NEW_PROPERTY_SUCCESS = "ADD_NEW_PROPERTY_SUCCESS";
export const ADD_NEW_PROPERTY_ERROR = "ADD_NEW_PROPERTY_ERROR";

export const PROPERTY_BY_ID_LOADING = "PROPERTY_BY_ID_LOADING";
export const PROPERTY_BY_ID_SUCCESS = "PROPERTY_BY_ID_SUCCESS";
export const PROPERTY_BY_ID_ERROR = "PROPERTY_BY_ID_ERROR";

export const GET_PAST_PROPERTIES = "GET_PAST_PROPERTIES";
export const GET_PAST_PROPERTIES_SUCCESS = "GET_PAST_PROPERTIES_SUCCESS";
export const GET_PAST_PROPERTIES_ERROR = "GET_PAST_PROPERTIES_ERROR";

export const GET_HOMEOWNERS = "GET_HOMEOWNERS";
export const GET_HOMEOWNERS_SUCCESS = "GET_HOMEOWNERS_SUCCESS";
export const GET_HOMEOWNERS_ERROR = "GET_HOMEOWNERS_ERROR";

export const GET_BUILDER_COMPANIES = "GET_BUILDER_COMPANIES";
export const GET_BUILDER_COMPANIES_SUCCESS = "GET_BUILDER_COMPANIES_SUCCESS";
export const GET_BUILDER_COMPANIES_ERROR = "GET_BUILDER_COMPANIES_ERROR";
