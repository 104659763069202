import React, { useCallback, useEffect } from "react";
import { Close, EditOutlined } from "@mui/icons-material";
import { IconButton, Typography, Grid, LinearProgress } from "@mui/material";
import { getMomentDate } from "core/utils/appUtils";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { fetchFiles, bulkUploadFiles } from "actions/documentsActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";
import "./punchList.scss";
import { fetchPunchItemByID } from "actions/punchListActions";
import { getPunchItem } from "selectors/punchListSelector";
import { TaskStatus } from "pages/task/addTaskUtils";
import { statusColors } from "../PunchList";

export const RenderPunchItemFields = ({ punchData }) => {
	const getFormattedDate = (date) => getMomentDate(date);

	const displayedFields = [
		{
			key: "title",
			label: "Title",
			dataPath: "title",
		},
		{
			key: "type",
			label: "Type",
			dataPath: "punchListType.punchListType",
		},
		{
			key: "location",
			label: "Location",
			dataPath: "location",
		},
		{
			key: "status",
			label: "Status",
			dataPath: "punchListStatusType.punchListStatusType",
		},
		{
			key: "dueDate",
			label: "Due Date",
			dataPath: "dueDate",
			formatter: (data) => getFormattedDate(data),
		},
		{
			key: "priority",
			label: "Priority",
			dataPath: "punchListPriority.punchListPriority",
			formatter: (priority) => (
				<TaskStatus status={priority || "Medium"} statusColors={statusColors} />
			),
		},
		{
			key: "assignedVendor",
			label: "Assigned Vendor",
			dataPath: "company.companyName",
		},
		{
			key: "assignedVendorTeamMember",
			label: "Assigned Vendor Team Member",
			dataPath: "assignedTeamMember",
		},
		{
			key: "scheduleImpact",
			label: "Schedule Impact",
			dataPath: "scheduleImpact",
		},
		{
			key: "costImpact",
			label: "Cost Impact",
			dataPath: "costImpact",
		},
		{
			key: "description",
			label: "Description",
			dataPath: "description",
		},
	];

	return (
		<div className="d-flex flex-wrap col-12 ">
			<div className={"col-12 mt-4"}>
				<div className="d-flex flex-wrap col-12">
					{displayedFields.map((field) => {
						return (
							<div className="col-4 mb-3">
								<Typography
									className="body1"
									style={{ fontWeight: 500, color: "#0B2240" }}
								>
									{field.label}
								</Typography>

								<Typography
									style={{
										wordBreak: "all",
										fontWeight: 700,
										color: "#0B2240",
									}}
									className="body1"
								>
									{field.formatter
										? field.formatter(get(punchData, field.dataPath, "NA"))
										: get(punchData, field.dataPath, "NA") || "NA"}
								</Typography>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export const PunchListFileManager = ({ punchItemId, punchData }) => {
	const dispatch = useDispatch();
	const existingFiles = useSelector(selectAllFiles);
	const isUploading = useSelector(selectIsUploading);

	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});
	const [documentTypeState, setDocumentTypeState] = React.useState("");

	const fetchExistingDocs = useCallback(() => {
		dispatch(fetchFiles("PunchList", punchItemId));
	}, [dispatch, punchItemId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	const handleUploadFiles = (onUploadSuccessCallback) => {
		dispatch(
			bulkUploadFiles(
				filesState,
				fileLabelsState,
				documentTypeState,
				punchItemId,
				"Job",
				() => {
					fetchExistingDocs();
					onUploadSuccessCallback();
				}
			)
		);
	};

	return (
		<>
			<div className="d-flex flex-wrap col-12 mb-5">
				<div className={"col-12 "}>
					<Typography className="h4 view-job-sec-header">Documents</Typography>

					<FileUpload
						setFilesState={setFilesState}
						setFileLabelsState={setFileLabelsState}
						setDocumentTypeState={setDocumentTypeState}
						onUploadButtonClick={handleUploadFiles}
						showUploadCancelButtons
					/>

					{/* Pass the files, labels, upload status, and existing files to FilesList */}
					<FilesList
						files={existingFiles || []}
						fileLabels={fileLabelsState}
						uploadStatus={{}}
						title="    "
						showToolbar
					/>
					{isUploading && (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justifyContent="center"
							sx={{ minHeight: "50vh" }}
						>
							<Grid item xs={3}>
								<LinearProgress size="3rem" />
							</Grid>
						</Grid>
					)}
				</div>
			</div>
		</>
	);
};

export const ViewPunchItem = ({
	projectId,
	propertyId,
	punchItemId,
	onEditPunchItem,
	onCancel,
}) => {
	const params = useParams();
	const dispatch = useDispatch();

	const punchData = useSelector(getPunchItem);

	useEffect(() => {
		dispatch(fetchPunchItemByID(params.punchItemId || punchItemId));
	}, [dispatch, params.punchItemId, punchItemId]);

	return (
		<div className="p-2 p-md-3">
			<div className="d-flex justify-content-between">
				<Typography className="h1 mt-3">{`${punchData.title} #${punchData.punchListCode}`}</Typography>
				<IconButton>
					<Close
						onClick={() => {
							if (onCancel) {
								onCancel();
							}
						}}
					/>
				</IconButton>
			</div>
			<div className="d-flex justify-content-end">
				<button className="primaryButton" onClick={onEditPunchItem}>
					<EditOutlined style={{ fontSize: "16px" }} /> Edit Punch Details
				</button>
			</div>
			<div className="d-flex flex-wrap">
				<div className="col-12">
					<div className="d-flex flex-wrap">
						<RenderPunchItemFields punchData={punchData} />
					</div>
				</div>
			</div>
			<PunchListFileManager punchItemId={punchItemId} punchData={punchData} />
		</div>
	);
};

export default ViewPunchItem;
