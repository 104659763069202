import request from "config/api";
import { ActionConstants } from "constants";
import { getServerUrl, server_builder_ai_url } from "constants/types";

export const fetchBuilderAiCommandsData = (roleId = -1) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_BUILDER_AI_COMMANDS_DATA,
				payload: {
					loading: true,
				},
			});

			request({
				method: "GET",
				url: `${server_builder_ai_url}/command?RoleId=${roleId}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_BUILDER_AI_COMMANDS_DATA_SUCCESS,
						payload: response.data,
					});
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.GET_BUILDER_AI_COMMANDS_DATA_ERROR,
						payload: {
							status: "error",
							message: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchBuilderAiCommandQuestions = (id) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_BUILDER_AI_COMMANDS_DATA,
				payload: {
					loading: true,
				},
			});

			request({
				method: "GET",
				url: `${server_builder_ai_url}/CommandQuestion?CommandId=${id}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_BUILDER_AI_COMMANDS_QUESTIONS_DATA_SUCCESS,
						payload: response.data,
					});
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.GET_BUILDER_AI_COMMANDS_DATA_ERROR,
						payload: {
							status: "error",
							message: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const setBuilderAiCommandQuestionsInitialData = () => {
	return (dispatch) => {
		dispatch({
			type: ActionConstants.SET_BUILDER_AI_COMMANDS_QUESTIONS_INITIAL_DATA,
			payload: {},
		});
	};
};

export const checkMicrophonePermissions = () => async (dispatch) => {
	let retval = false;

	console.log("Checking microphone permissions...");

	const permissions = await getPermissions();

	if (permissions === "prompt") {
		retval = true;
	}

	await navigator.mediaDevices
		.getUserMedia({ audio: true })
		.then((stream) => (retval = true))
		.catch((err) => (retval = false));

	dispatch({
		type: ActionConstants.GET_BUILDER_AI_MICROPHONE_ACCESS,
		payload: retval,
	});
};

export const fetchBuilderAiCommandOptionsData = async (questionId) => {
	let response = {};

	try {
		response = await request({
			method: "GET",
			url: `${server_builder_ai_url}/CommandQuestion/Options/${questionId}`,
		});
	} catch (e) {
		console.log("fetchBuilderAiCommandOptionsData error", e);
	}

	return response?.data;
};

export const executeBuilderAiCommand = async (path, method, data) => {
	let response = {};

	try {
		response = await request({
			method,
			url: `${getServerUrl()}/${path}`,
			body: data,
		});
	} catch (e) {
		console.log("executeBuilderAiCommand error", e);
	}

	return response?.data;
};

async function getPermissions() {
	console.log("Checking permissions:getPermissions()");
	if (!!navigator?.permissions) {
		return (
			navigator.permissions
				// @ts-ignore - ignore because microphone is not in the enum of name for all browsers
				?.query({ name: "microphone" })
				.then((result) => result.state)
				.catch((err) => {
					return "prompt";
				})
		);
	}
	return "prompt";
}
