import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
	Typography,
	IconButton,
	Grid,
	Box,
	LinearProgress,
	CircularProgress,
	TextField,
	Autocomplete,
	// createFilterOptions,
	FormHelperText,
} from "@mui/material";
import { addPunchListForm, addPunchListFields } from "./addPunchListUtils";
import get from "lodash/get";
import { CloseOutlined } from "@mui/icons-material";
import "./punchList.scss";
import FormInputField, {
	FormLabel,
} from "components/TextFields/FormInputField";
import DatePickerField from "components/DateComponets/DatePicker";
import { addUpdateProject } from "actions/projectActions";
import { getUserProfileDetails } from "selectors/appSelector";
import dayjs from "dayjs";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { fetchFiles } from "actions/documentsActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";

import {
	addUpdatePunchListAction,
	fetchPunchPriorityListAction,
	fetchPunchStatusListAction,
	fetchPunchTypeListAction,
} from "actions/punchListActions";
import { getDynamicPunchOptions } from "selectors/punchListSelector";
import { fetchJobAssigneesListAction } from "actions/jobActions";
import { getAssigneeList } from "selectors/jobSelectors";
import { isSystemAdmin } from "core/utils/roleUtils";

// const filter = createFilterOptions();

export const NewPuchItem = ({
	propertyId,
	onCancel,
	projectId,
	selectedPunchItem = {},
	title,
	fromProperty,
}) => {
	const dispatch = useDispatch();
	const punchItemId = get(selectedPunchItem, "data.punchListId", null);
	const appRoleDetails = useSelector(getUserProfileDetails);
	console.log("Check the punchItemId", punchItemId);
	const { validationSchema } = addPunchListForm;
	const initialState = addPunchListForm.defaultState(
		get(selectedPunchItem, "data", null)
	);
	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		validateOnMount: false,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (event) => {
			dispatch(
				addUpdatePunchListAction({
					formData: {
						...formik.values,
						projectId: projectId,
						propertyId: propertyId,
					},
					filesData: { filesState, fileLabelsState, documentTypeState },
					onSuccess: onCancel,
					params: { projectId },
				})
			);
		},
		// initialTouched: get(selectedProject, "data", null),
	});

	//Files Upload and Retrival
	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});
	const [documentTypeState, setDocumentTypeState] = React.useState("");

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);

	const existingFiles = useSelector(selectAllFiles);

	const isUploading = useSelector(selectIsUploading);

	const punchOptions = useSelector(getDynamicPunchOptions);

	const { assignedTo } = useSelector(getAssigneeList);

	const dynamicOptions = {
		...punchOptions,
		companyId: isSystemAdmin(appRoleDetails)
			? []
			: assignedTo.map((company) => ({
					key: company.companyId,
					text: company.companyName,
			  })),
		// companyId: assignedTo,
	};

	const fetchExistingDocs = useCallback(() => {
		if (punchItemId) {
			dispatch(fetchFiles("PunchList", punchItemId));
		}
	}, [dispatch, punchItemId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	useEffect(() => {
		dispatch(fetchPunchTypeListAction());
		dispatch(fetchPunchPriorityListAction());
		dispatch(fetchPunchStatusListAction());
		dispatch(fetchJobAssigneesListAction());
	}, [dispatch, projectId]);

	const handleSubmit = (e) => {
		e.preventDefault();
		formik.handleSubmit();
		// Touch all fields on submit to show validation errors
		const allFields = addPunchListFields.reduce(
			(acc, field) => ({
				...acc,
				[field.key]: true,
			}),
			{}
		);
		formik.setTouched(allFields, true);
	};

	return (
		<div className="mt-0 p-1" style={{ position: "relative" }}>
			<>
				<div className="d-flex justify-content-between">
					<Typography className="text-left m-2 h1" id="header">
						{title || "Create New Punch"}
					</Typography>
					<IconButton onClick={() => onCancel()}>
						<CloseOutlined />
					</IconButton>
				</div>
				<div id="create-project" className="mt-2">
					<form
						onKeyDown={(e) => {
							if (e.key === "Enter") e.preventDefault(); // Prevent "Enter" key submission
						}}
						onSubmit={handleSubmit}
					>
						<div id="container row">
							<div id="section-1 mt-3">
								<div className="form-fields col-12 ">
									<div className="container row justify-content-between">
										{addPunchListFields.map((field) => {
											const extraInputProps = get(field, "extraInputProps", {});

											const extraProps =
												typeof extraInputProps === "function"
													? extraInputProps(formik)
													: extraInputProps;

											if (field.type === "dateField") {
												return (
													<div
														className={`form-field-wrapper ${field.className}`}
														style={{ width: field.width }}
													>
														<FormLabel
															text={field.label}
															required={field.required}
														/>
														<DatePickerField
															onChange={(date) => {
																if (
																	!date ||
																	dayjs(date).isValid() === false ||
																	dayjs(date).year() === 1970
																) {
																	formik.setFieldValue(field.key, null); // Explicitly set to null
																} else {
																	formik.setFieldValue(field.key, date);
																}
															}}
															onClear={() => {
																formik.setFieldValue(field.key, null);
															}}
															value={formik.values[field.key] || null}
															field={field}
															slotProps={{
																field: {
																	clearable: !field.required,
																	onClear: () => {
																		formik.setFieldValue(field.key, null);
																	},
																},
															}}
															{...extraProps}
														/>
														{formik.errors[field.key] && (
															<FormHelperText
																style={{ fontSize: "12px" }}
																error
																text={formik.errors[field.key]}
															>
																{formik.errors[field.key]}
															</FormHelperText>
														)}
													</div>
												);
											}
											if (field.dynamicField) {
												return (
													<div
														className={`form-field-wrapper ${field.className}`}
														style={{ width: field.width }}
													>
														<FormLabel
															text={field.label}
															required={field.required}
														/>
														<Autocomplete
															freeSolo
															onChange={(_, value) => {
																formik.setFieldValue(field.key, value?.key);
															}}
															id="companies-autocomplete"
															value={
																dynamicOptions[field.key].find(
																	(option) =>
																		option.key === formik.values[field.key]
																) || null
															}
															className="form-field autocomplete-field"
															open={autoCompleteOpen}
															onOpen={() => setAutoCompleteOpen(true)}
															onClose={() => setAutoCompleteOpen(false)}
															isOptionEqualToValue={(option, value) => {
																return option.key === value.companyId;
															}}
															getOptionLabel={(option) => {
																return option.text;
															}}
															options={dynamicOptions[field.key]}
															renderInput={(params) => (
																<TextField
																	{...params}
																	className="form-field"
																	onChange={(event, value) => {
																		const textLen = event.target.value.length;
																		if (textLen >= 3) {
																			console.log("Fetching the record");
																		}
																	}}
																	InputProps={{
																		...params.InputProps,
																		endAdornment: (
																			<React.Fragment>
																				{false ? (
																					<CircularProgress
																						color="inherit"
																						size={20}
																					/>
																				) : null}
																				{params.InputProps.endAdornment}
																			</React.Fragment>
																		),
																	}}
																/>
															)}
														/>
													</div>
												);
											}

											return (
												<FormInputField
													field={{
														...field,
														options: dynamicOptions[field.key] || field.options,
													}}
													formik={formik}
													value={formik.values[field.key]}
													key={field.key}
													id={field.id}
													{...extraProps}
												/>
											);
										})}
									</div>
								</div>
							</div>
							<div data-testid="document-upload" className="mt-3">
								<div className="d-flex flex-wrap col-12 pt-5 mb-1">
									<div className={"col-12 "}>
										{/* <div className="d-flex flex-wrap col-12"> */}
										<h5>Documents</h5>

										<FileUpload
											setFilesState={setFilesState}
											setFileLabelsState={setFileLabelsState}
											setDocumentTypeState={setDocumentTypeState}
										/>
										{/* Pass the files, labels, upload status, and existing files to FilesList */}
										{punchItemId && (
											<FilesList
												files={existingFiles || []}
												fileLabels={fileLabelsState}
												uploadStatus={{}}
											/>
										)}
										{isUploading && (
											<Grid
												container
												spacing={0}
												direction="column"
												alignItems="center"
												justifyContent="center"
											>
												<Box sx={{ width: "100%" }}>
													<Typography variant="body1">
														Uploading Documents
													</Typography>
													<LinearProgress size="3rem" />
												</Box>
											</Grid>
										)}
									</div>
								</div>
							</div>
							<div>
								<Typography variant="body1" className="mt-1 ">
									<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
									<strong>Required Fields</strong>
								</Typography>
							</div>
							<div className="mt-2">
								<div className="d-flex justify-content-center align-self-center">
									<button
										// type="submit"
										className={`secondaryButton m-3`}
										onClick={() => {
											onCancel();
										}}
									>
										{"Cancel"}
									</button>
									<button
										// type="submit"
										className={`primaryButton m-3`}
										style={{ width: "auto" }}
									>
										{projectId ? "Save Changes" : "Create Project"}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</>
		</div>
	);
};

export default NewPuchItem;
