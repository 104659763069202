import axios from "axios";
import {
  PROJECT_GET_WARRANTIES_LOADING,
  PROJECT_GET_WARRANTIES,
  PROJECT_WARRANTY_STATUS_ERROR,
  PROJECT_ADD_WARRANTY,
  PROJECT_ADD_WARRANTY_ERROR,
  PROJECT_ADD_WARRANTY_SUCCESS,
  PROJECT_DELETE_WARRANTY_SUCCESS,
  PROJECT_WARRANTY_CLEAR_SNACKBAR,
  PROJECT_GET_ADS,
  PROJECT_ADS_STATUS_ERROR,
  PROJECT_GET_ADS_LOADING,
  PROJECT_ADD_ADS,
  PROJECT_ADD_ADS_SUCCESS,
  PROJECT_ADD_ADS_ERROR,
  PROJECT_DELETE_ADS_SUCCESS,
  PROJECT_ADS_CLEAR_SNACKBAR,
  PROJECT_ADS_AUTOCOMPLETE_LOADING,
  PROJECT_GET_ADS_AUTOCOMPLETE,
  PROJECT_ADS_AUTOCOMPLETE_STATUS_ERROR,
} from "../constants/types";
import queryString from "query-string";
import { omit } from "lodash";

// import { bulkUploadFiles } from "./documentsActions";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchAds = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_GET_ADS_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PageNumber: params.page ? params.page + 1 : 1,
        PageSize: params.pageSize ? params.pageSize : 10,
      };
      const queryParams = queryString.stringify(newParams);

      request({
        url: `${server_url}/Advertise?${queryParams}`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: PROJECT_GET_ADS,
            payload: {
              ads: response.data.items,
              totalCount: response.data.totalCount,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_ADS_STATUS_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting all ads.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateAdvertise = ({
  formData,
  onSuccess,
  payload,
  advertiseImg,
}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_ADD_ADS,
        payload: {
          loading: true,
        },
      });

      let data = {
        ...formData,
      };

      if (formData.advertiseId && formData.advertiseImage === null) {
        data = { ...data, advertiseImage: formData?.originalImage };
      }

      data = omit(data, "logo1");
      data = omit(data, "advertiseImage1");
      data = omit(data, "originalImage");

      console.log("data before send", {});
      request({
        method: formData.advertiseId ? "PUT" : "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: `${server_url}/Advertise${
          formData.advertiseId ? `/${formData.advertiseId}` : ""
        }`,
        body: {
          _Advertise: { ...data, advertiseImage: null },
          advertiseImage: formData.advertiseImage,
        },
      })
        .then(async (response) => {
          onSuccess();

          dispatch({
            type: PROJECT_ADD_ADS_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: formData.advertiseId
                ? "Ad updated Successfully"
                : "Ad Created Successfully",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_ADD_ADS_ERROR,
            payload: {
              text: "Error occurred during creating Ad data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const adsDelete = ({
  advertiseId,
  pageModel,
  onSuccess = () => {},
  showSnackbar = true,
}) => {
  console.log("Check the adsDelete Action", advertiseId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_GET_ADS_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/Advertise/${advertiseId}`,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(fetchAds({ ...pageModel }));
          onSuccess();

          dispatch({
            type: PROJECT_DELETE_ADS_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "Ads Deleted Successfully",
              loading: false,
              showSnackbar: true,
            },
          });

          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_ADS_STATUS_ERROR,
            payload: {
              text: "Error occurred during deleting ads data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const adsClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: PROJECT_ADS_CLEAR_SNACKBAR,
    });
  };
};
export const adsMarketCompany = ({ text }) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_ADS_AUTOCOMPLETE_LOADING,
        payload: {
          companiesLoading: true,
        },
      });
      request({
        url: `${server_url}/mktCompany/List?Searchtxt=${text}`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: PROJECT_GET_ADS_AUTOCOMPLETE,
            payload: {
              companies: response.data,
              companiesLoading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_ADS_AUTOCOMPLETE_STATUS_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting autoComplete.",
              companiesLoading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};
