import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
	TextField,
	Box,
	Typography,
	IconButton,
	FormHelperText,
	Grid,
	LinearProgress,
	Autocomplete,
	CircularProgress,
} from "@mui/material";

import { addPropertyForm } from "./addPropertyUtils";
import get from "lodash/get";
import { LocationField } from "components/Location/GeoLocation";
import {
	addNewProperty,
	fetchBuilderCompaniesAction,
	fetchHomeownersAction,
} from "actions/propertyActions";
import FormInputField, {
	FormLabel,
} from "components/TextFields/FormInputField";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getUserProfileDetails } from "selectors/appSelector";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { fetchFiles } from "actions/documentsActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";
import "./properties.scss";
import { useNavigate } from "react-router-dom";
import AppRoleContext from "context/AppRoleContext";
import {
	getBuilderCompanies,
	getHomeOwners,
} from "selectors/propertySelectors";
import { isHomeOwner } from "core/utils/roleUtils";

export const AddNewProperty = ({
	onCancel = () => {
		window.history.back();
	},
	propertyId,
	selectedProperty,
	title,
	callback = () => {},
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [activeStep] = useState(0);

	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});

	const [documentTypeState, setDocumentTypeState] = useState(null);

	const existingFiles = useSelector(selectAllFiles);

	const isUploading = useSelector(selectIsUploading);

	const appRoleDetails = useSelector(getUserProfileDetails);

	const { homeOwnersList } = useSelector(getHomeOwners);
	const { builderCompaniesList } = useSelector(getBuilderCompanies);

	const dynamicOptions = {
		userId: homeOwnersList.map((homeOwner) => ({
			key: homeOwner.userId,
			text: `${homeOwner.firstName} ${homeOwner.lastName}`,
			...homeOwner,
		})),
		companyId: builderCompaniesList.map((company) => ({
			key: company.companyId,
			text: company.companyName,
			...company,
		})),
	};

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);

	const profileRole = useContext(AppRoleContext);

	const { formFields, validationSchema } = addPropertyForm;

	const initialState = addPropertyForm.defaultState(
		get(selectedProperty, "data", null)
	);

	const fetchExistingDocs = useCallback(() => {
		if (propertyId) {
			dispatch(fetchFiles("Property", propertyId));
		}
	}, [dispatch, propertyId]);

	const fetchDynamicOptions = useCallback(() => {
		dispatch(fetchHomeownersAction());
		dispatch(fetchBuilderCompaniesAction());
	}, [dispatch]);

	useEffect(() => {
		fetchDynamicOptions();
	}, [fetchDynamicOptions]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			dispatch(
				addNewProperty(
					{
						...formik.values,
						companyId: isHomeOwner(appRoleDetails)
							? null
							: appRoleDetails.companyId,
					},
					{ filesState, fileLabelsState, documentTypeState },
					(payload) => {
						callback(payload);
						// onCancel();
					},
					navigate
				)
			);
		},
		initialTouched: get(selectedProperty, "data", null),
	});

	return (
		<div className="mt-2 p-2">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h1" id="header">
					{title || "Create New Property"}
				</Typography>
				<IconButton onClick={() => onCancel()}>
					<CloseOutlined />
				</IconButton>
			</div>
			<div className="form-section">
				<div>
					<form
						onKeyDown={(e) => {
							if (e.key === "Enter") e.preventDefault(); // Prevent "Enter" key submission
						}}
						onSubmit={formik.handleSubmit}
					>
						<div className="stepContent container row">
							{formFields[`step${activeStep}`].map((field, index) => {
								const extraInputProps = get(field, "extraInputProps", {});

								const extraProps =
									typeof extraInputProps === "function"
										? extraInputProps(formik)
										: extraInputProps;
								if (
									field.renderCondition &&
									!field.renderCondition(
										formik.values["propertyType"],
										profileRole
									)
								) {
									return null;
								}
								if (field.key === "address") {
									return (
										<div
											className={`${field.className} mt-3`}
											// style={{ width: field.width }}
										>
											<FormLabel text={field.label} required={field.required} />

											<div className="google-location-field">
												<LocationField
													defaultAddress={formik.values.address}
													onSelect={(payload) => {
														const addressObj = payload.addressObj;
														formik.setFieldValue(
															"propertyLocation",
															payload.locationUrl
														);
														formik.setFieldValue("address", payload.address);
														formik.setFieldTouched("address", true, false);
														formik.setFieldValue(
															"latitude",
															payload.latLng.lat
														);
														formik.setFieldValue(
															"longitude",
															payload.latLng.lng
														);
														for (let key in addressObj) {
															formik.setFieldValue(key, addressObj[key]);
															if (addressObj[key] && addressObj[key] !== "") {
																formik.setFieldTouched(key, true, false);
															}
														}
														// formik.setFieldValue("locationUrl", payload.locationUrl);
													}}
												/>
												{formik.errors["address"] && (
													<FormHelperText
														style={{ fontSize: "12px" }}
														error
														text={formik.errors["propertyLocation"]}
													>
														{formik.errors["propertyLocation"]}
													</FormHelperText>
												)}
											</div>
										</div>
									);
								}
								if (field.dynamicField) {
									return (
										<div
											className={`form-field-wrapper ${field.className}`}
											style={{ width: field.width }}
										>
											<FormLabel text={field.label} required={field.required} />
											<Autocomplete
												freeSolo
												onChange={(_, value) => {
													formik.setFieldValue(field.key, value?.key);
												}}
												id="companies-autocomplete"
												value={
													dynamicOptions[field.key].find(
														(option) => option.key === formik.values[field.key]
													) || null
												}
												className="form-field autocomplete-field"
												open={autoCompleteOpen}
												onOpen={() => setAutoCompleteOpen(true)}
												onClose={() => setAutoCompleteOpen(false)}
												isOptionEqualToValue={(option, value) => {
													return option.key === value.companyId;
												}}
												getOptionLabel={(option, a, b, c) => {
													return option.text;
												}}
												options={dynamicOptions[field.key]}
												renderInput={(params) => (
													<TextField
														{...params}
														className="form-field"
														onChange={(event, value) => {
															const textLen = event.target.value.length;
															if (textLen >= 3) {
																console.log("Fetching the record");
															}
														}}
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{false ? (
																		<CircularProgress
																			color="inherit"
																			size={20}
																		/>
																	) : null}
																	{params.InputProps.endAdornment}
																</React.Fragment>
															),
														}}
													/>
												)}
											/>
										</div>
									);
								}
								return (
									<FormInputField
										field={{
											...field,
											options: dynamicOptions[field.key] || field.options,
										}}
										formik={formik}
										value={formik.values[field.key]}
										key={field.key}
										id={field.id}
										{...extraProps}
									/>
								);
							})}
							<div data-testid="document-upload" className="mt-3">
								<div className="d-flex flex-wrap col-12 pt-5 mb-3">
									<div className={"col-12 "}>
										{/* <div className="d-flex flex-wrap col-12"> */}
										<h5>Property Documents</h5>

										<FileUpload
											setFilesState={setFilesState}
											setFileLabelsState={setFileLabelsState}
											setDocumentTypeState={setDocumentTypeState}
										/>

										{/* Pass the files, labels, upload status, and existing files to FilesList */}
										{propertyId && (
											<FilesList
												files={existingFiles || []}
												fileLabels={fileLabelsState}
												uploadStatus={{}}
											/>
										)}
										{isUploading && (
											<Grid
												container
												spacing={0}
												direction="column"
												alignItems="center"
												justifyContent="center"
											>
												<Box sx={{ width: "100%" }}>
													<Typography variant="body1">
														Uploading Documents
													</Typography>
													<LinearProgress size="3rem" />
												</Box>
											</Grid>
										)}
										{/* </div> */}
									</div>
								</div>
							</div>
							<div>
								<Typography variant="body1" className="mt-3 ">
									<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
									<strong>Required Fields</strong>
								</Typography>
							</div>
							<div style={{ textAlign: "right" }}>
								<div className="d-flex justify-content-center">
									<div>
										<button
											type="submit"
											className={`save-details-button m-4 outlinedButton`}
											onClick={() => {
												onCancel();
											}}
										>
											{"Cancel"}
										</button>
									</div>
									<div className="float-right">
										<button
											type="submit"
											className={`save-details-button m-4 primaryButton`}
										>
											{propertyId ? "Save Changes" : "Create Property"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewProperty;
