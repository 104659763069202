import { ActionConstants } from "constants";

const initialState = {
	punchListItems: [],
	totalCount: 0,
	punchTypeList: [],
	punchPriorityList: [],
	punchStatusList: [],
	punchItem: [],
};

const punchListReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.FETCH_PUNCH_LISTS_SUCCESS:
			return {
				...state,
				punchListItems: action.payload.punchLists,
				totalCount: action.payload.totalCount,
			};
		case ActionConstants.FETCH_PUNCH_TYPE_LIST_SUCCESS:
			return {
				...state,
				punchTypeList: action.payload,
			};
		case ActionConstants.FETCH_PUNCH_PRIORITIES_LIST_SUCCESS:
			return {
				...state,
				punchPriorityList: action.payload,
			};
		case ActionConstants.FETCH_PUNCH_STATUS_LIST_SUCCESS:
			return {
				...state,
				punchStatusList: action.payload,
			};
		case ActionConstants.FETCH_PUNCH_ITEM_BY_ID_SUCCESS:
			return {
				...state,
				punchItem: action.payload,
			};
		default:
			return state;
	}
};

export default punchListReducer;
