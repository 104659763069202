import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.jobStatus;

export const getJobStatus = createSelector(selectState, (jobStatus) => ({
  jobStatus: get(jobStatus, "jobStatus", []),
  totalCount: get(jobStatus, "totalCount", 0),
  loading: get(jobStatus, "loading", false),
  showSnackbar: get(jobStatus, "showSnackbar", false),
  status: get(jobStatus, "status", "success"),
  statusText: get(jobStatus, "text", ""),
}));
