export const FETCH_PUNCH_LISTS = "FETCH_PUNCH_LISTS";
export const FETCH_PUNCH_LISTS_SUCCESS = "FETCH_PUNCH_LISTS_SUCCESS";
export const FETCH_PUNCH_LISTS_ERROR = "FETCH_PUNCH_LISTS_ERROR";

export const FETCH_PUNCH_ITEM_BY_ID = "FETCH_PUNCH_ITEM_BY_ID";
export const FETCH_PUNCH_ITEM_BY_ID_SUCCESS = "FETCH_PUNCH_ITEM_BY_ID_SUCCESS";
export const FETCH_PUNCH_ITEM_BY_ID_ERROR = "FETCH_PUNCH_ITEM_BY_ID_ERROR";

export const CREATE_UPDATE_PUNCH_LIST = "CREATE_UPDATE_PUNCH_LIST";
export const CREATE_UPDATE_PUNCH_LIST_SUCCESS =
	"CREATE_UPDATE_PUNCH_LIST_SUCCESS";
export const CREATE_UPDATE_PUNCH_LIST_ERROR = "CREATE_UPDATE_PUNCH_LIST_ERROR";

export const DELETE_PUNCH_LIST = "DELETE_PUNCH_LIST";
export const DELETE_PUNCH_LIST_SUCCESS = "DELETE_PUNCH_LIST_SUCCESS";
export const DELETE_PUNCH_LIST_ERROR = "DELETE_PUNCH_LIST_ERROR";

export const FETCH_PUNCH_TYPE_LIST = "FETCH_PUNCH_TYPE";
export const FETCH_PUNCH_TYPE_LIST_SUCCESS = "FETCH_PUNCH_TYPE_SUCCESS";
export const FETCH_PUNCH_TYPE_LIST_ERROR = "FETCH_PUNCH_TYPE_ERROR";

export const FETCH_PUNCH_PRIORITIES_LIST = "FETCH_PUNCH_PRIORITIES";
export const FETCH_PUNCH_PRIORITIES_LIST_SUCCESS =
	"FETCH_PUNCH_PRIORITIES_SUCCESS";
export const FETCH_PUNCH_PRIORITIES_LIST_ERROR = "FETCH_PUNCH_PRIORITIES_ERROR";

export const FETCH_PUNCH_STATUS_LIST = "FETCH_PUNCH_STATUS";
export const FETCH_PUNCH_STATUS_LIST_SUCCESS = "FETCH_PUNCH_STATUS_SUCCESS";
export const FETCH_PUNCH_STATUS_LIST_ERROR = "FETCH_PUNCH_STATUS_ERROR";
