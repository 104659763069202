import React, { useState, useEffect, useCallback } from "react";

import NoResultsFound from "components/NoResults/NoResultsFound";
import { Box, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SETTINGS_GET_COMPANIES } from "constants/types";
import { getMarketPlaceCompanies } from "selectors/marketPlaceSelectors";
import { fetchMarketCompanies } from "actions/marketCompanyActions";
import MarketCompany from "./MarketCompany";
import GeneralSettings from "./general_settings/AppConfigUI";
import AppConfigUI from "./general_settings/AppConfigUI";
import RenderTabs from "components/Tabs/Tabs";
import ManageAlerts from "./ManageAlerts";
import Ads from "./Ads/Ads";
import MasterData from "./MasterData/MasterData";

const tabs = [
  {
    key: "generalSettings",
    label: "General Settings",
    TabComponent: AppConfigUI,
  },
  {
    key: "marketplaceCompanies",
    label: "Marketplace Companies",
    TabComponent: MarketCompany,
  },
  {
    key: "alertsConfig",
    label: "Manage Alerts",
    TabComponent: ManageAlerts,
  },
  {
    key: "Ads",
    label: "Ads",
    TabComponent: Ads,
  },
  {
    key: "MasterData",
    label: "MasterData",
    TabComponent: MasterData,
  },
];

export const Settings = () => {
  const [value, setValue] = useState("generalSettings");

  console.log("Check the value : ", value);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <RenderTabs
          tabs={tabs}
          selectedTab={value}
          onTabClick={(newValue) => {
            setValue(newValue);
          }}
        />
        {/* <Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab label="General Settings" {...a11yProps(0)} />
					<Tab label="Marketplace Companies" {...a11yProps(1)} />
				</Tabs> */}
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
				<AppConfigUI />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<MarketCompany />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2}>
				Item Three
			</CustomTabPanel> */}
    </Box>
  );
};
export default Settings;
