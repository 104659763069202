import { Box } from "@mui/material";
import RenderTabs from "components/Tabs/Tabs";
import React, { useState } from "react";
import ProjectStatus from "../ProjectStatus/ProjectStatus";
import JobType from "../JobType/JobType";
import JobStatus from "../JobStatus/JobStatus";

const tabs = [
  {
    key: "Project Status",
    label: "Project Status",
    TabComponent: ProjectStatus,
  },
  {
    key: "JobType",
    label: "JobType",
    TabComponent: JobType,
  },
  {
    key: "JobStatus",
    label: "JobStatus",
    TabComponent: JobStatus,
  },
];

const MasterData = () => {
  const [value, setValue] = useState("Project Status");

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <RenderTabs
          tabs={tabs}
          orientation="vertical"
          selectedTab={value}
          onTabClick={(newValue) => {
            setValue(newValue);
          }}
        />
      </Box>
    </Box>
  );
};

export default MasterData;
