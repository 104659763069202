import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.projectStatus;

export const getProjectStatus = createSelector(
  selectState,
  (projectStatus) => ({
    projectStatus: get(projectStatus, "projectStatus", []),
    totalCount: get(projectStatus, "totalCount", 0),
    loading: get(projectStatus, "loading", false),
    showSnackbar: get(projectStatus, "showSnackbar", false),
    status: get(projectStatus, "status", "success"),
    statusText: get(projectStatus, "text", ""),
  })
);

// export const marketPlaceLoadingSelector = createSelector(selectState, (app) =>
//   get(app, "loading", false)
// );

// export const marketPlaceSnackBarSelector = createSelector(selectState, (app) =>
//   get(app, "showSnackbar", false)
// );
