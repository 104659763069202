import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.property;

export const getProperties = createSelector(selectState, (properties) => ({
	totalCount: properties.totalCount || 10,
	propertiesList: get(properties, "propertyList", []),
}));

export const getPastProperties = createSelector(selectState, (properties) => {
	return get(properties, "pastProperties", []);
});

export const getPropertyData = createSelector(
	selectState,
	(properties) => properties.propertyData
);

export const getHomeOwners = createSelector(selectState, (properties) => ({
	homeOwnersList: get(properties, "homeOwnersList", []),
	homeOwnersTotalCount: get(properties, "homeOwnersTotalCount", false),
}));

export const getBuilderCompanies = createSelector(
	selectState,
	(properties) => ({
		builderCompaniesList: get(properties, "builderCompaniesList", []),
		builderCompaniesTotalCount: get(
			properties,
			"builderCompaniesTotalCount",
			false
		),
	})
);
