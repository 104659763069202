import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAlertNotificationsAction } from "actions/appActions";

// Custom hook for notification polling
export const useNotificationPolling = (interval = 600000) => {
	// default 10 minutes
	const dispatch = useDispatch();

	useEffect(() => {
		// Initial fetch
		dispatch(fetchAlertNotificationsAction());

		// Set up polling interval
		const pollInterval = setInterval(() => {
			dispatch(fetchAlertNotificationsAction());
		}, interval);

		// Cleanup on unmount
		return () => clearInterval(pollInterval);
	}, [dispatch, interval]);
};
