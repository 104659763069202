import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import {
	showAppApiAlert,
	startAppLoadAction,
	stopAppLoadAction,
} from "./appActions";
import queryString from "query-string";
import { identityFn } from "core/utils/appUtils";

export const fetchTemplatesAction =
	(payload, onSuccess) => async (dispatch) => {
		dispatch({ type: ActionConstants.GET_TEMPLATES_REQUEST });

		try {
			request({
				method: "GET",
				url: `${server_url}/estimationTemplate`,
			})
				.then(async (response) => {
					dispatch({
						type: ActionConstants.GET_TEMPLATES_SUCCESS,
						payload: response.data,
					});
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.GET_TEMPLATES_ERROR,
						error: {
							text: "Error occurred during document upload : " + e.message,
							status: "error",
							loading: false,
							showSnackbar: true,
						},
					});
					throw new Error("Failed to upload document");
				});
		} catch (error) {
			dispatch({
				type: ActionConstants.GET_TEMPLATES_ERROR,
				error: error.message,
			});
		}
	};

export const fetchEstimatesAction =
	(payload = {}, onSuccess) =>
	async (dispatch) => {
		dispatch(startAppLoadAction());
		dispatch({ type: ActionConstants.GET_ESTIMATES_REQUEST });
		const params = queryString.stringify(payload.params);
		try {
			request({
				method: "GET",
				url: `${server_url}/projectEstimate${params ? `?${params}` : ""}`,
			})
				.then(async (response) => {
					dispatch({
						type: ActionConstants.GET_ESTIMATES_SUCCESS,
						payload: {
							data: response.data,
							totalCount: response.data.totalCount,
						},
					});
					dispatch(stopAppLoadAction());
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.GET_ESTIMATES_ERROR,
						error: {
							text: "Error occurred during document upload : " + e.message,
							status: "error",
							loading: false,
							showSnackbar: true,
						},
					});
					dispatch(stopAppLoadAction());
				});
		} catch (error) {
			dispatch({
				type: ActionConstants.GET_TEMPLATES_ERROR,
				error: error.message,
			});
			dispatch(stopAppLoadAction());
		}
	};

export const fetchEstimationFieldsAction =
	(payload, onSuccess) => async (dispatch) => {
		console.log("In Action : ", payload);
		dispatch({ type: ActionConstants.GET_TEMPLATE_FIELDS_REQUEST });
		try {
			request({
				method: "GET",
				url: `${server_url}/estimate?estimateId=${payload.templateId}`,
			})
				.then(async (response) => {
					dispatch({
						type: ActionConstants.GET_TEMPLATE_FIELDS_SUCCESS,
						payload: response.data,
					});
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.GET_TEMPLATE_FIELDS_ERROR,
						error: {
							text: "Error occurred during document upload : " + e.message,
							status: "error",
							loading: false,
							showSnackbar: true,
						},
					});
					throw new Error("Failed to upload document");
				});
		} catch (error) {
			dispatch({
				type: ActionConstants.GET_TEMPLATE_FIELDS_ERROR,
				error: error.message,
			});
		}
	};

// Save Project Estimate Template Action
export const saveEstimateTemplateAction =
	(payload, onSuccess = () => {}) =>
	async (dispatch) => {
		dispatch(startAppLoadAction());
		dispatch({ type: ActionConstants.SAVE_PROJECT_ESTIMATE_REQUEST });
		try {
			request({
				method: payload.estimateId ? "POST" : "POST",
				url: `${server_url}/ProjectEstimate`,
				body: {
					estimateName: payload.estimateName,
					projectID: payload.projectId || null,
					templateID: payload.templateId,
					propertyID: payload.propertyId,
					description: payload.description || null,
					companyID: payload.companyId,
				},
			})
				.then(async (response) => {
					dispatch({
						type: ActionConstants.SAVE_PROJECT_ESTIMATE_SUCCESS,
						payload: response.data,
					});
					if (onSuccess) onSuccess(response.data);
					dispatch(stopAppLoadAction());
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.SAVE_PROJECT_ESTIMATE_ERROR,
						error: {
							text:
								"Error occurred while saving project estimate: " + e.message,
							status: "error",
							loading: false,
							showSnackbar: true,
						},
					});
					dispatch(stopAppLoadAction());

					throw new Error("Failed to save project estimate");
				});
		} catch (error) {
			dispatch({
				type: ActionConstants.SAVE_PROJECT_ESTIMATE_ERROR,
				error: error.message,
			});
			dispatch(stopAppLoadAction());
		}
	};

// Save Project Estimate Fields Action

export const saveEstimateItemsAction =
	(estimateId, payload, onSuccess = identityFn) =>
	async (dispatch) => {
		dispatch(startAppLoadAction());
		dispatch({ type: ActionConstants.SAVE_ESTIMATE_ITEMS_REQUEST });
		try {
			request({
				method: "POST",
				url: `${server_url}/estimate?estimateId=${estimateId}`,
				body: payload,
			})
				.then(async (response) => {
					dispatch({
						type: ActionConstants.SAVE_ESTIMATE_ITEMS_SUCCESS,
						payload: response.data,
					});
					if (onSuccess) onSuccess(response.data);
					dispatch(fetchEstimationFieldsAction({ templateId: estimateId }));
					dispatch(
						showAppApiAlert({
							showAlert: true,
							message: "Estimate saved successfully",
							type: "success",
						})
					);
					dispatch(stopAppLoadAction());
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.SAVE_ESTIMATE_ITEMS_ERROR,
						error: {
							text:
								"Error occurred while saving project estimate items: " +
								e.message,
							status: "error",
							loading: false,
							showSnackbar: true,
						},
					});
					dispatch(
						showAppApiAlert({
							showAlert: true,
							message: e.message,
							type: "error",
						})
					);
					dispatch(stopAppLoadAction());
				});
		} catch (error) {
			dispatch({
				type: ActionConstants.SAVE_ESTIMATE_ITEMS_ERROR,
				error: error.message,
			});
			dispatch(stopAppLoadAction());
		}
	};

export const convertEstimateToProjectAction =
	(estimateId, payload, onSuccess = identityFn) =>
	async (dispatch) => {
		dispatch(startAppLoadAction());
		dispatch({ type: ActionConstants.CONVERT_ESTIMATE_TO_PROJECT_REQUEST });
		try {
			request({
				method: "GET",
				url: `${server_url}/estimate/converEstimateToProject?estimateId=${estimateId}`,
				// body: payload,
			})
				.then(async (response) => {
					dispatch({
						type: ActionConstants.CONVERT_ESTIMATE_TO_PROJECT_SUCCESS,
						payload: response.data,
					});
					if (onSuccess) onSuccess(response.data);
					dispatch(
						showAppApiAlert({
							showAlert: true,
							message: "Projects & Jobs are being created from this estimate",
							type: "success",
						})
					);
					dispatch(stopAppLoadAction());
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.CONVERT_ESTIMATE_TO_PROJECT_ERROR,
						error: {
							text:
								"Error occurred while creating project from this estimate: " +
								e.message,
							status: "error",
							loading: false,
							showSnackbar: true,
						},
					});
					dispatch(
						showAppApiAlert({
							showAlert: true,
							message: e.message,
							type: "error",
						})
					);
					dispatch(stopAppLoadAction());
				});
		} catch (error) {
			dispatch({
				type: ActionConstants.SAVE_ESTIMATE_ITEMS_ERROR,
				error: error.message,
			});
			dispatch(stopAppLoadAction());
		}
	};
