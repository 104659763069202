import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.punchList;

export const getPunchLists = createSelector(selectState, (punchList) => ({
	totalCount: punchList.totalCount || 10,
	punchListItems: get(punchList, "punchListItems", []),
}));

export const getPunchTypeList = createSelector(selectState, (punchList) => {
	return get(punchList, "punchTypeList", []).map((item) => ({
		key: item.punchListTypeId,
		text: item.punchListType,
	}));
});

export const getPunchPriorityList = createSelector(selectState, (punchList) => {
	return get(punchList, "punchPriorityList", []).map((item) => ({
		key: item.punchListPriorityId,
		text: item.punchListPriority,
	}));
});

export const getPunchStatusList = createSelector(selectState, (punchList) => {
	return get(punchList, "punchStatusList", []).map((item) => ({
		key: item.punchListStatusTypeId,
		text: item.punchListStatusType,
	}));
});

export const getDynamicPunchOptions = createSelector(
	getPunchTypeList,
	getPunchPriorityList,
	getPunchStatusList,
	(punchTypeList, punchPriorityList, punchStatusList) => {
		return {
			punchListTypeId: punchTypeList,
			punchListPriorityId: punchPriorityList,
			punchListStatusTypeId: punchStatusList,
		};
	}
);

export const getPunchItem = createSelector(selectState, (punchList) => {
	return get(punchList, "punchItem", {});
});
