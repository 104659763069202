import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	IconButton,
	Paper,
	Typography,
	Divider,
	MenuItem,
	Menu,
	TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
	getEstimatesList,
	getTemplateFields,
} from "selectors/estimatesSelectors";
import {
	ArrowBack,
	Edit,
	KeyboardArrowDown,
	KeyboardArrowRight,
	NoteAdd,
} from "@mui/icons-material";
import NoResultsFound from "components/NoResults/NoResultsFound";

import {
	fetchEstimatesAction,
	fetchEstimationFieldsAction,
	saveEstimateItemsAction,
} from "actions/estimationsActions";
import { useNavigate, useParams } from "react-router-dom";
import { CustomBreadCrumb } from "components/BreadCrumb";
import { ModelDialog } from "components/Model";

import EstimateDownload from "./EstimateDownload";
import DownloadPdf from "components/Downloads/CreatePDF";
import { CreateEstimateContent } from "./CreateEstimate";
import RenderDataFields from "components/DataFields/RenderDataFields";
import { EstimateProjectCreate } from "./RenderEstimateActions";

const EstimateTemplate = (props) => {
	const { isDownload } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const templateFields = useSelector(getTemplateFields);
	const { estimatesList } = useSelector(getEstimatesList);

	const params = useParams();

	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [expandedRows, setExpandedRows] = useState(new Set());

	const [dowloadingFile, setDownloadingFile] = useState(false);
	const [downloadFormat, setDownloadFormat] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);

	const [estimateEdit, setEstimateEdit] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const [showCustomFields, setShowCustomFields] = useState(false);
	const [customFieldData, setCustomFieldData] = useState("");

	// Inside your component:

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleFormatSelect = (format) => {
		setDownloadFormat(format);
		setDownloadingFile(true);
		handleClose();
	};
	const fetchEstimations = useCallback(() => {
		dispatch(
			fetchEstimatesAction({ params: { estimateId: params.estimateId } })
		);
	}, [dispatch, params.estimateId]);

	useEffect(() => {
		fetchEstimations();
	}, [fetchEstimations]);

	useEffect(() => {
		if (params.estimateId && params.estimateId > 0 && !isDownload) {
			// templateId: params.estimateId;
			dispatch(fetchEstimationFieldsAction({ templateId: params.estimateId }));
		}
	}, [dispatch, params.estimateId, isDownload]);

	useEffect(() => {
		if (templateFields.length > 0) {
			setRows(templateFields);
		}
	}, [templateFields]);

	// Group rows by jobName
	const groupedRows = useMemo(() => {
		return rows.reduce((groups, row) => {
			if (!groups[row.jobName]) {
				groups[row.jobName] = [];
			}
			groups[row.jobName].push(row);
			return groups;
		}, {});
	}, [rows]);

	const handleInputChange = (rowIndex, jobName, taskName, field, value) => {
		const newRows = [...rows];
		const globalIndex = newRows.findIndex(
			(row) =>
				row.taskName === taskName &&
				row.estimateItemID === groupedRows[jobName][rowIndex].estimateItemID
		);

		if (globalIndex !== -1) {
			const parsedValue = parseFloat(value);
			newRows[globalIndex][field] = isNaN(parsedValue) ? null : parsedValue;
			newRows[globalIndex].total = calculateRowTotal(newRows[globalIndex]);
			setRows(newRows);
		}
	};

	const calculateRowTotal = (row) => {
		const laborCost = (row.hours || 0) * (row.laborRate || 0);
		const materialCost = (row.units || 0) * (row.materialRate || 0);
		const fixedCost = row.fixedCost || 0;
		return laborCost + materialCost + fixedCost;
	};

	const handleCalculate = () => {
		const totalEstimate = rows.reduce((sum, row) => sum + (row.total || 0), 0);
		setTotal(totalEstimate);
	};

	const calculateGroupTotal = (groupRows) => {
		return groupRows.reduce((sum, row) => sum + (row.total || 0), 0);
	};

	if (templateFields.length === 0) {
		return (
			<div className="mt-4">
				<NoResultsFound message={"No records found"} />
			</div>
		);
	}

	const toggleExpand = (id) => {
		const newExpanded = new Set(expandedRows);
		if (newExpanded.has(id)) {
			newExpanded.delete(id);
		} else {
			newExpanded.add(id);
		}
		setExpandedRows(newExpanded);
	};

	const tableFields = [
		{ field: "hours", text: "Hours" },
		{
			field: "laborRate",
			text: "Labor Rate",
		},
		{
			field: "units",
			text: "Units",
		},
		{
			field: "materialRate",
			text: "Material Rate",
		},
		{
			field: "fixedCost",
			text: "Fixed Cost",
		},
	];

	const estimateDataFields = [
		{
			key: "estimateName",
			label: "Estimate Name",
			dataPath: "estimateName",
			width: "30%",
		},
		{
			key: "estimateDescription",
			label: "Estimate Description",
			dataPath: "estimateDescription",
			width: "30%",
		},
		{
			key: "propertyName",
			label: "Property",
			dataPath: "property.address",
			width: "30%",
			formatter: (value) => {
				return (
					<Typography
						className="body1 link-text"
						onClick={() => {
							navigate(`/properties/${estimatesList[0].propertyId}`, {
								state: {
									propertyId: estimatesList[0].propertyId,
									propertyName: estimatesList[0].propertyName,
								},
							});
						}}
					>
						{value}
					</Typography>
				);
			},
		},
		{
			key: "homeOwnerName",
			label: "HomeOwner Name",
			dataPath: "property.homeOwnerName",
			width: "30%",
		},
		{
			key: "homeOwnerMobile",
			label: "Contact",
			dataPath: "property.homeOwnerMobile",
			width: "30%",
		},
	];

	if (props?.isDownload) {
		return <EstimateDownload groupRows={groupedRows} data={rows} {...props} />;
	}

	// Handle PDF download completion
	const handleDownloadComplete = () => {
		setDownloadingFile(false);
	};

	const newRowSchema = {
		avgDays: 1,
		estimateId: estimatesList[0].estimateId,
		estimateItemId: null,
		fixedCost: null,
		hours: null,
		jobName: "",
		laborRate: null,
		materialRate: null,
		taskName: "",
		templateId: 1,
		templateItemId: null,
		total: null,
		units: null,
	};

	return (
		<div className="p-3">
			<ModelDialog
				fullWidth={true}
				open={showCustomFields}
				onClose={() => setShowCustomFields(false)}
				dialogTitle={
					<Typography className="h3">{`Add New Row to ${customFieldData?.jobName}`}</Typography>
				}
				dialogContent={
					<div>
						{console.log("Check the customFieldData", customFieldData)}
						<div className="d-flex align-center full-width">
							<Typography className="h5 col-4">Enter Task name</Typography>
							<TextField
								className="form-field"
								value={customFieldData.taskName}
								onChange={(e) =>
									setCustomFieldData({
										...customFieldData,
										taskName: e.target.value,
									})
								}
							/>
						</div>
						<div className="d-flex align-center justify-content-center full-width mt-3">
							<button
								className="primaryButton full-width"
								onClick={() => {
									setRows([...rows, customFieldData]);
									setShowCustomFields(false);
									// setCustomFieldData(null);
								}}
							>
								Create
							</button>
						</div>
					</div>
				}
			/>
			<ModelDialog
				fullWidth={true}
				open={dowloadingFile}
				onClose={() => setDownloadingFile(false)}
				title="Creating Estimations"
				dialogContent={
					<div>
						<RenderDownloadEstimations
							downloadFormat={downloadFormat}
							handleDownloadComplete={handleDownloadComplete}
						/>
					</div>
				}
				dialogTitle={
					<Typography className="h5">Creating Estimations</Typography>
				}
			/>
			<ModelDialog
				fullWidth={true}
				open={showConfirmDialog}
				onClose={() => setShowConfirmDialog(false)}
				title="Create Project"
				dialogContent={
					<EstimateProjectCreate
						estimateData={estimatesList[0]}
						handleClose={() => {
							setShowConfirmDialog(false);
						}}
					/>
				}
			/>
			<div className="back-to-nav mb-4">
				<div
					className="d-flex align-items-center pointer "
					onClick={() => {
						navigate("/builder/estimations");
					}}
				>
					<ArrowBack
						style={{ height: "20px", width: "23px", color: "rgb(52, 93, 174)" }}
					/>
					Back To Estimates
				</div>
				<CustomBreadCrumb breadCrumbItems={["estimates", "estimateName"]} />
			</div>
			<div className="mb-4">
				<Paper style={{ width: "100%", padding: "1rem" }}>
					<div className="d-flex justify-content-between mb-4 align-center">
						<Typography className="h3">Estimate Details</Typography>
						{!estimateEdit && (
							<div>
								<button
									className="secondaryButton disabled"
									style={{ width: "5rem" }}
									onClick={() => {
										setEstimateEdit(true);
									}}
									disabled
								>
									<Edit style={{ fontSize: "18px" }} /> Edit
								</button>
								<button
									className="primaryButton"
									style={{ background: "#28a745" }}
									onClick={() => {
										setShowConfirmDialog(true);
									}}
								>
									Create Project
								</button>
							</div>
						)}
					</div>
					{estimateEdit ? (
						<CreateEstimateContent
							buttonText="Update Details"
							handleClose={() => {
								setEstimateEdit(false);
								fetchEstimations();
							}}
							estimateDetails={estimatesList[0]}
						/>
					) : (
						<RenderDataFields
							data={estimatesList[0]}
							fields={estimateDataFields}
						/>
					)}
				</Paper>
			</div>
			<TableContainer component={Paper}>
				<Table className="w-full cstm-datagrid-table mb-4">
					<TableHead className="cstm-datagrid-table-header">
						<TableRow className="cstm-datagrid-table-row">
							<TableCell
								style={{ width: " 5%" }}
								className="cstm-col-header header-toggle-expand"
							></TableCell>
							<TableCell
								style={{ width: " 25%" }}
								className="cstm-col-header estimate-header-job"
							>
								Job
							</TableCell>
							{tableFields.map((col) => (
								<TableCell
									style={{ width: "8%" }}
									className={`cstm-col-header estimate-header-${col.field}`}
								>
									{col.text}
								</TableCell>
							))}
							<TableCell
								style={{ width: "10%" }}
								className="cstm-col-header estimate-header-total"
							>
								Total
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(groupedRows || {}).map(
							([jobName, groupRows], grpIndex) => (
								<>
									<TableRow
										className={`cstm-data-row estimate-grp-row-${grpIndex} pointer`}
										style={{ background: "#345DAE99", color: "white" }}
										onClick={() => toggleExpand(grpIndex)}
									>
										<TableCell className="cstm-datagrid-data-col">
											{" "}
											<IconButton
												size="small"
												style={{ height: "10px" }}
												onClick={() => toggleExpand(grpIndex)}
											>
												{expandedRows.has(grpIndex) ? (
													<KeyboardArrowDown
														style={{
															width: "1.5rem",
															color: "white",
															fontWeight: "700",
														}}
													/>
												) : (
													<KeyboardArrowRight
														style={{
															width: "1.5rem",
															color: "white",
															fontWeight: "700",
														}}
													/>
												)}
											</IconButton>
										</TableCell>
										<TableCell
											className="cstm-datagrid-data-col"
											style={{
												color: "white",
												fontWeight: "700",
												fontSize: "16px",
											}}
										>
											{jobName}
										</TableCell>
										{tableFields.map((_) => (
											<TableCell
												style={{ width: "8%" }}
												className="cstm-datagrid-data-col"
											></TableCell>
										))}
										<TableCell
											className="cstm-datagrid-data-col"
											style={{
												color: "white",
												fontWeight: "700",
												fontSize: "16px",
											}}
										>
											$ {calculateGroupTotal(groupRows).toFixed(2)}
										</TableCell>
									</TableRow>
									{expandedRows.has(grpIndex) && (
										<>
											{groupRows.map((row, subIndex) => (
												<>
													<TableRow
														key={row.estimateItemID}
														className={`cstm-data-row estimate-grp-row-${subIndex}`}
													>
														<TableCell
															style={{ width: " 8%" }}
															className="cstm-datagrid-data-col"
														></TableCell>
														<TableCell
															style={{ width: " 25%" }}
															className="cstm-datagrid-data-col"
														>
															{row.taskName}
														</TableCell>
														{tableFields.map((col) => (
															<TableCell
																style={{ width: "8%" }}
																className="cstm-datagrid-data-col"
															>
																<input
																	type="number"
																	className="p-1 border rounded"
																	style={{ width: "5rem" }}
																	value={row[col.field] || ""}
																	onChange={(e) =>
																		handleInputChange(
																			subIndex,
																			jobName,
																			row.taskName,
																			col.field,
																			e.target.value
																		)
																	}
																/>
															</TableCell>
														))}
														<TableCell
															style={{ width: "10%" }}
															className="cstm-datagrid-data-col text-right"
														>
															${row.total?.toFixed(2) || "0.00"}
														</TableCell>
													</TableRow>
												</>
											))}

											<TableRow style={{}}>
												<TableCell></TableCell>
												<TableCell>
													<button
														className="primaryButton full-width"
														style={{ width: "6rem" }}
														onClick={() => {
															setShowCustomFields(true);
															setCustomFieldData({ ...newRowSchema, jobName });
														}}
													>
														+ Add New Row
													</button>
												</TableCell>
											</TableRow>
										</>
									)}
								</>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<div className="mt-4 d-flex justify-content-between">
				<div className="">
					<button onClick={handleCalculate} className="primaryButton">
						Calculate
					</button>
					<div className="mt-2 text-lg font-semibold">
						Total Estimation: ${total.toFixed(2)}
					</div>
				</div>
				<div className="d-flex">
					<button
						className="primaryButton w-auto"
						onClick={() => {
							// setShowSaveModel(true);
							dispatch(
								saveEstimateItemsAction(params.estimateId, rows, () => {})
							);
						}}
					>
						Save Estimate
					</button>
					<div>
						<button
							className="primaryButton"
							onClick={handleClick}
							style={{ width: "10rem" }}
						>
							Download As <KeyboardArrowDown />
						</button>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							slotProps={{
								paper: { style: { minWidth: "10rem" } },
							}}
						>
							<MenuItem onClick={() => handleFormatSelect("pdf")}>
								<Typography className="h6">PDF</Typography>
							</MenuItem>
							<MenuItem onClick={() => handleFormatSelect("xlsx")}>
								<Typography className="h6">Excel</Typography>
							</MenuItem>
						</Menu>
					</div>
				</div>
			</div>
		</div>
	);
};

// Add display name for dev tools
EstimateTemplate.displayName = "RenderTemplate";

const RenderDownloadEstimations = ({
	downloadFormat,
	handleDownloadComplete,
}) => {
	if (downloadFormat === "pdf") {
		return (
			<div>
				<DownloadPdf
					shouldSavePDF={true}
					onDownloadComplete={handleDownloadComplete}
					fileName="BudgetEstimation.pdf"
				>
					{/* Pass RenderTemplate and other content to the PDF */}
					<div>
						<Typography className="h4">Budget Estimation Template</Typography>
						<Divider className="divider" />
						<EstimateTemplate
							shouldSave={true}
							onDownloadComplete={handleDownloadComplete}
							isDownload={true}
							fileName="BudgetEstimation.xlsx"
						/>
					</div>
				</DownloadPdf>
			</div>
		);
	}
	if (downloadFormat === "xlsx") {
		return (
			<EstimateTemplate
				shouldSave={true}
				type={"xlsx"}
				onDownloadComplete={handleDownloadComplete}
				isDownload={true}
				fileName="BudgetEstimation.xlsx"
			/>
		);
	}
	return null;
};

export default EstimateTemplate;
