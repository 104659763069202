import {
	PROPERTY_LOADING,
	PROPERTY_GET_ALL_SUCCESS,
	PROPERTY_ERROR,
	PROPERTY_CLEAR_SNACKBAR,
} from "../constants/types";

import { ActionConstants } from "constants";

const initialState = {
	propertyList: [],
	totalCount: 0,
	loading: true,
	status: "success",
	text: "",
	showSnackbar: false,
	propertyData: {},
	pastProperties: [],
	pastPropertiesLoading: false,
	homeOwnersList: [],
	homeOwnersTotalCount: 0,
	builderCompaniesList: [],
	builderCompaniesTotalCount: 0,
};

const propertyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.ADD_NEW_PROPERTY_SUCCESS:
			return {
				...state,
			};
		case ActionConstants.ADD_NEW_PROPERTY_ERROR:
			return {
				...state,
			};
		case PROPERTY_CLEAR_SNACKBAR:
			return {
				...state,
				showSnackbar: false,
			};
		case PROPERTY_LOADING:
			return {
				...state,
				loading: true,
			};
		case PROPERTY_ERROR:
			return {
				...state,
				propertyList: [],
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case PROPERTY_GET_ALL_SUCCESS:
			return {
				...state,
				propertyList: action.payload.propertyList,
				totalCount: action.payload.totalCount,
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
				hasMore: action.payload.hasMore,
			};
		case ActionConstants.PROPERTY_BY_ID_LOADING: {
			return {
				...state,
				propertyData: {},
			};
		}
		case ActionConstants.PROPERTY_BY_ID_SUCCESS: {
			return {
				...state,
				propertyData: action.payload.propertyData,
			};
		}
		case ActionConstants.GET_PAST_PROPERTIES: {
			return {
				...state,
				pastPropertiesLoading: true,
			};
		}
		case ActionConstants.GET_PAST_PROPERTIES_SUCCESS: {
			return {
				...state,
				pastProperties: action.payload.pastProperties,
				pastPropertiesLoading: false,
			};
		}
		case ActionConstants.GET_PAST_PROPERTIES_ERROR: {
			return {
				...state,
				pastPropertiesLoading: false,
			};
		}
		case ActionConstants.GET_HOMEOWNERS_SUCCESS: {
			return {
				...state,
				homeOwnersList: action.payload.homeOwnersList,
				homeOwnersTotalCount: action.payload.totalCount,
			};
		}
		case ActionConstants.GET_BUILDER_COMPANIES_SUCCESS: {
			return {
				...state,
				builderCompaniesList: action.payload.builderCompaniesList,
				builderCompaniesTotalCount: action.payload.total,
			};
		}

		default:
			return state || {};
	}
};

export default propertyReducer;
