import {
  PROJECT_GET_WARRANTIES,
  PROJECT_GET_WARRANTIES_LOADING,
  PROJECT_WARRANTY_STATUS_ERROR,
  PROJECT_WARRANTY_CLEAR_SNACKBAR,
  PROJECT_DELETE_WARRANTY_SUCCESS,
  PROJECT_ADD_WARRANTY_SUCCESS,
  PROJECT_ADD_WARRANTY_ERROR,
  PROJECT_ADS_STATUS_ERROR,
  PROJECT_ADD_ADS_ERROR,
  PROJECT_ADD_ADS_SUCCESS,
  PROJECT_DELETE_ADS_SUCCESS,
  PROJECT_GET_ADS_LOADING,
  PROJECT_GET_ADS,
  PROJECT_ADS_CLEAR_SNACKBAR,
  PROJECT_ADS_AUTOCOMPLETE_LOADING,
  PROJECT_GET_ADS_AUTOCOMPLETE,
  PROJECT_ADS_AUTOCOMPLETE_STATUS_ERROR,
} from "../constants/types";

import { BULK_UPLOAD_FILE_SUCCESS } from "../constants/documentsConstants";

const initialState = {
  ads: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
  documentsList: [],
  companies: [],
  companiesLoading: false,
};

const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_ADS_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case PROJECT_GET_ADS:
      return {
        ...state,
        ...action.payload,
        showSnackbar: false,
      };
    case PROJECT_GET_ADS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PROJECT_DELETE_ADS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case PROJECT_ADD_ADS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case PROJECT_ADD_ADS_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case PROJECT_ADS_STATUS_ERROR:
      return {
        ...state,
        // warranties: [],
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case PROJECT_GET_ADS_AUTOCOMPLETE:
      return {
        ...state,
        ...action.payload,
      };
    case PROJECT_ADS_AUTOCOMPLETE_LOADING:
      return {
        ...state,
        ...action.payload,
      };
    case PROJECT_ADS_AUTOCOMPLETE_STATUS_ERROR:
      return {
        ...state,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };

    default:
      return state || {};
  }
};

export default adsReducer;
