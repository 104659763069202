import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.advertisement;

export const getAds = createSelector(selectState, (ads) => ({
  ads: get(ads, "ads", []),
  totalCount: get(ads, "totalCount", 0),
  loading: get(ads, "loading", false),
  showSnackbar: get(ads, "showSnackbar", false),
  status: get(ads, "status", "success"),
  statusText: get(ads, "text", ""),
  companies: get(ads, "companies", []),
  companiesLoading: get(ads, "companiesLoading", false),
}));

// export const marketPlaceLoadingSelector = createSelector(selectState, (app) =>
//   get(app, "loading", false)
// );

// export const marketPlaceSnackBarSelector = createSelector(selectState, (app) =>
//   get(app, "showSnackbar", false)
// );
